<script setup>
import { useLeadActions } from '@lead';
import { useActivityActions } from '@activity';
import { useProfileActions } from '@profile';

const getLeadResource = useLeadActions('getLeadResource');
const getProfileResource = useProfileActions('getProfileResource');

const lead = getLeadResource();
const profile = getProfileResource();
const {
    blockCustomer,
    logoutCustomer
} = useLeadActions();

const getActivityResource = useActivityActions('getActivityResource');
const activity = getActivityResource(
    { filters: { lead_id: [lead.model.id] }, },
    true,
    'LeadActivity Component'
);

function onBlockCustomer(id, isLocked) {
    blockCustomer(id, (isLocked === 'Yes') ? 'No' : 'Yes').then(() => {
        lead.model.customer.is_locked = (isLocked === 'Yes') ? 'No' : 'Yes';
    });
}

function onLeadLogout(id, online_at) {
    if (online_at === null) {
        logoutCustomer(id, true).then(() => {
            lead.model.customer.online_at = new Date();
        });
    }
}

</script>

<template>
    <g-button type="button"
              class="online-indicator"
              v-bind:disabled="!!lead.model.customer.online_at"
              v-on:click="onLeadLogout(lead.model.customer.id, lead.model.customer.online_at)">
       {{$t('lead.logout_lead')}}
    </g-button>
    <g-button type="button"
              class="blocked-indicator"
              v-if="profile.can('Lead Lock')"
              v-on:click="onBlockCustomer(lead.model.customer.id, lead.model.customer.is_locked)">
        <span v-if="lead.model.customer.is_locked === 'No'">{{$t('lead.block_lead')}}</span>
        <span v-else>{{$t('lead.unlock_lead')}}</span>
    </g-button>
    <div class="activity">
        <g-preloader v-if="activity.isLoading"/>
        <g-caption class="info" weight="500" size="4" v-if="!activity.state.length && !activity.isLoading">
            <div>{{ $t('base.no_activity') }}</div>
        </g-caption>
        <div class="action" v-for="action in activity.state" v-bind:key="`action-${action.id}`">
            <div v-if="action.logout_at" class="time logout">
                Logout: <span class="success">{{ action.logout_at }}</span>
            </div>
            <div v-else class="online">Online</div>
            <ul class="container">
                <li>
                    <span class="key">Type:</span>
                    <span class="value" v-bind:class="action.type.toLowerCase()">{{ action.type }}</span>
                </li>
                <li>
                    <span class="key">Device:</span>
                    <span class="value">
                        {{ action.specifics?.platform || action.platform }} / <span class="warning">{{
                            action.specifics?.device || action.device
                        }}</span> / {{ action.specifics?.os || action.os }}
                    </span>
                </li>
                <li>
                    <span class="key">Agent:</span>
                    <span class="value agent">{{ action.specifics?.browser || action.browser }}</span>
                </li>
                <li>
                    <span class="key">IP:</span>
                    <span class="value">{{ action.ip }}</span>
                </li>
                <li>
                    <span class="key">Timezone:</span>
                    <span class="value">{{ action.specifics?.timezone || 'n/a' }}</span>
                </li>
                <li>
                    <span class="key">Location:</span>
                    <span class="value">
                        {{ action.specifics?.country || 'n/a' }} / <span class="warning">{{
                            action.specifics?.region || 'n/a'
                        }}</span> / {{ action.specifics?.city || 'n/a' }}
                    </span>
                </li>
                <li>
                    <span class="key">ASN:</span>
                    <span class="value">
                        {{ action.specifics?.asn_type || 'n/a' }} / <span class="warning">{{
                            action.specifics?.asn_name || 'n/a'
                        }}</span> / {{ action.specifics?.asn_domain || 'n/a' }}
                    </span>
                </li>
            </ul>
            <div class="time login">
                Login: <span class="warning">{{ action.created_at }}</span>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.activity {
    margin-left: 8px;
    border-left: 1px solid var(--separator-color);
}

.action {
    margin: 20px 0;
    padding: 10px 0;
}

.time,
.online {
    width: 186px;
    padding: 4px 8px;
    position: relative;
    box-sizing: content-box;
    border-radius: var(--btn-rounding, $btn-rounding);

    &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: -26px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        display: inline-block;
        background-color: var(--separator-color, $separator-color);
    }

    &::after {
        content: '';
        position: absolute;
        top: 12px;
        left: -22px;
        width: 22px;
        height: 1px;
        background-color: var(--separator-color, $separator-color);
    }
}

.time {
    color: var(--title-text-color, $title-text-color);

    &.login {
        border: 1px solid var(--warning, $warning);
    }

    &.logout {
        border: 1px solid var(--success, $success);
    }
}

.online {
    color: white;
    text-align: center;
    background-color: var(--danger, $danger);
}

ul {
    padding: 10px 5px;

    li {
        margin: 6px 0;
        white-space: nowrap;

        & > .key {
            width: 74px;
            display: inline-block;
            color: var(--disabled-color, $disabled-color);
            text-align: right;
        }

        & > .value {
            padding: 0 5px;
            white-space: normal;
            display: inline-block;
            vertical-align: top;
            max-width: calc(100% - 80px);
            color: var(--title-text-color, $title-text-color);

            &.impersonate {
                color: var(--warning, $warning);
            }

            &.autologin {
                color: var(--danger-hover, $danger-hover);
            }

            &.agent {
                color: var(--main-text-color, $secondary-hover);
            }
        }
    }
}

.info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 18px;
    margin-top: 50%;

    & > div {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
}

.g-button {
    padding: 5px 10px;
    height: 26px !important;
    margin: 10px 4px;
    font-size: 12px !important;
    background-color: var(--primary, $primary);
    color: #ffffff;
    position: relative;

    &.online-indicator,
    &.blocked-indicator {
        &:hover {
            background: var(--primary-hover, $primary-hover);
        }
    }
}
</style>