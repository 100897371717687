<template>
    <div class="leads environment">
        <indexbar v-on:perpage="changePerPage">
            <g-search-input v-bind:label="$t('base.search')" v-on:find="find"
                            v-on:drop="leads.load(null, {}, 'LeadEnv Search')"/>
            <date-filter/>
            <g-button class="filters-btn"
                    v-on:click="PopUp
                    .open(require('@lead/components/PopUps/LeadFilters.vue'))
                    .setCaption($t('lead.filters', 'Filters'))">
                <g-symbol name="filter" width="16" height="16"/>
               {{$t('lead.filters', 'Filters')}}
            </g-button>
            <g-button
                class="mass-update-btn filters-btn with-tooltip"
                v-bind:disabled="!targets.length"
                v-bind:class="{'is-active': targets.length > 0}"
                v-on:click="openMassUpdate"
                data-tooltip="Mass Update">
                <g-symbol name="people" width="16" height="16" />
                Mass Update
            </g-button>
        </indexbar>

        <div class="index">
            <draggable class="row header" v-model="columns" v-bind:item-key="key => key">
                <template #item="{ element: column }">                    
                    <div
                        class="title cell"
                        v-bind:class="[column.key, {sortable: leads.sortBy === column.key, draggable: column.draggable !== false}, leads.sortDir]"
                        scope="col"
                        v-on:vue:mounted="onCellMounted(column.draggable, $event)"
                        v-on:dragend="onColumnDragEnd">
                        <div v-if="column.key === 'check_all'" class="check-all" >
                            <g-symbol name="check" width="18" height="18" v-on:click="checkAll"/>
                        </div>
                        <g-flex v-else-if="column.sortable"
                                align="center"
                                justify="start"
                                gap="2"
                                v-bind:class="column.key"
                                v-on:click="sortBy(column.key)">
                            {{ $t(`lead.${column.name}`) }}
                            <g-symbol name="arrow-down" width="14" height="14"/>
                        </g-flex>
                        <div v-else v-bind:class="column.key">{{ $t(`lead.${column.name}`) }}</div>
                    </div>
                </template>
            </draggable>
            <g-preloader-content :is-loading="profile.isLoading || leads.isLoading"/>
            <div class="row"
                 v-for="(lead, index) in leads.state"
                 v-bind:key="`lead-${lead.id}`"
                 v-on:click="openLead(lead.id)"
                 v-bind:class="{checked: targets.includes(lead.id.toString()), open: $route.params.id === lead.id.toString()}"
                 v-show="!lead.mixed">
                <component
                    v-bind:is="column.key === 'check_all' ? 'label' : 'div'"
                    v-for="column in columns"
                    v-bind:key="column.key"
                    class="cell"
                    v-bind:class="[column.key, {
                        sortable: leads.sortBy === column.key,
                        checkbox: column.key === 'check_all',
                        id: column.key === 'id',
                    }]">
                    <template v-if="column.key === 'check_all'">
                        <g-flex v-if="profile.can('Lead LabelView')" class="bookmark" direction="column">
                            <span v-if="!!lead.label" v-for="bookmark in lead.label.split(',')"
                                v-bind:key="`${bookmark}-${lead.id}`"
                                v-bind:class="bookmark"></span>
                        </g-flex>
                        <g-checkbox v-model="targets"
                                    v-bind:value="lead.id.toString()"
                                    v-on:click.stop="handleCheckboxClick($event, lead, index)" class="black"/>
                    </template>
                    <g-flex v-else-if="column.key === 'id'" align="center" gap="10">
                        {{ lead.id }}
                        <g-symbol name="external_link" width="16" height="16" v-on:click.stop="openInNewTab(lead.id)" />
                    </g-flex>
                    <template v-else-if="column.key === 'phone'">
                        <g-copy v-if="profile.can('Lead ContactsView')" class="phone" v-bind:text="lead.phone"/>
                        <template v-else>
                            {{ lead.phone }}
                        </template>

                    </template>
                    <fieldset v-else-if="column.key === 'commentDate' && !!lead[column.name]">
                        <legend>{{ lead[column.name].created_at }}</legend>
                        <div>{{ lead[column.name].body }}</div>
                    </fieldset>
                    <template v-else-if="column.key === 'campaigns' && !!lead[column.name]">
                        {{
                            profile.can('Lead RealCampaignView')
                                ? [...new Set(lead[column.name].map(item => item.name))].join(', ')
                                : [...new Set(lead[column.name].map(item => item.alias))].join(', ')
                        }}
                    </template>
                    <span v-else-if="['is_autologin', 'is_ftd', 'is_test', 'phone_confirmed', 'email_confirmed'].includes(column.key)"
                          v-bind:class="(lead[column.name] || '').toLowerCase()">
                        {{ lead[column.name] }}
                    </span>
                    <template v-else>
                        {{
                            Array.isArray(lead[column.name])
                                ? [...new Set(lead[column.name].map(item => item.name))].join(', ')
                                : lead[column.name]
                        }}
                    </template>
                </component>
            </div>
            <div class="info-block" v-if="!leads.state.length && !leads.isLoading">{{
                    $t('base.nothing_found')
                }}
            </div>
        </div>

        <g-pagination v-model="leads.meta.current_page"
                      :class="[{'pagination-float-left': $route.params.id},{'pagination-float-right': STATE.IS_SHOW_SIDEBAR}]"
                      v-on:change="paginate"
                      v-bind:per-page="leads.meta.per_page"
                      v-bind:total="leads.meta.total"/>
    </div>
</template>

<script>

import { computed, ref, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useLeadActions, useLeadComponents } from '@lead';
import { useProfileActions } from '@profile';

import { preloaderAligner } from '@/Ship/components/global/preloaders/scrollHellper';
import { i18n, PopUpManager as PopUp, STATE } from '@/Ship';
import draggable from "vuedraggable";

const {
    Indexbar,
    MassUpdate,
    DateFilter
} = useLeadComponents();

const getLeadResource = useLeadActions('getLeadResource');
const getProfileResource = useProfileActions('getProfileResource');

export default {
    name: 'Environment',
    computed: {
        STATE() {
            return STATE;
        }
    },
    components: {
        DateFilter,
        Indexbar,
        MassUpdate,
        draggable,
    },

    setup() {
        const router = useRouter();
        const route = useRoute();

        const profile = getProfileResource();
        const leads = getLeadResource();

        const targets = ref([]);
        let lastSelectedIndex = null;

        function find(value) {
            if (route.name === 'Lead') {
                router.push({ name: 'Leads', params: route.params });
            }

            const key = Object.keys(value).pop();

            if ((key === 'id' && value[key].length) || value[key].length > 2) {
                leads.find(value);
            }
        }

        function openLead(id) {

            //leads.index = leads.state.findIndex(item => item.id === id);
            //if (leads.index > -1) {
            let isLock = false;
            Object.keys(STATE.UNSAVED_DATA).forEach(key => {
                if (STATE.UNSAVED_DATA[key].length > 0) {
                    isLock = true;
                }
            });
            if (isLock) {
                PopUp.open(require('@/Ship/components/PopUps/UnSavedAlert')).setCaption(i18n.global.t('message.saving_data', 'Saving data'));
            } else {
                leads.show(id, { include: 'campaigns,customer,activity,sale,retention' }).then(() => {
                    leads.index = leads.state.findIndex(item => item.id === id);
                });
                router.push({ name: 'Lead', params: { ...route.params, id } });
            }
            //} else {
            //    leads.index = null;
            //    router.push({ name: 'Leads', params: route.params });
            //}
        }

        function changePerPage(value) {
            leads.meta.per_page = value;
            leads.setPerPage(value);
            leads.setPage(1);
            leads.load(null, {}, 'LeadEnv PerPage');

            profile.state.lead.perPage = value;
            profile.saveState();
        }

        function paginate(page) {
            leads.setPage(page);
            leads.load(null, {}, 'LeadEnv Paginate');
        }

        function reloadLeads() {
            targets.value = [];
            return leads.load(null, {}, 'LeadEnv Refresh');
        }

        function sortBy(field) {
            if (leads.sortBy === field) {
                profile.state.lead.sortDir = leads.sortDir === 'asc'
                    ? 'desc'
                    : 'asc';
            }
            profile.state.lead.sortBy = field;
            profile.saveState();

            leads.sort(
                profile.state.lead.sortBy,
                profile.state.lead.sortDir
            );
            leads.load(null, {}, 'LeadEnv Resort');
        }

        const userState = computed(() => {
            return profile.state.lead || {};
        });

        leads.onload.push(() => {
            const objectIds = leads.state.map((obj) => obj.id);
            targets.value = targets.value.filter((target) => objectIds.includes(parseInt(target)));
        });

        onMounted(() => {
            document.addEventListener('keydown', handleKeyPress);
            leads.sort(
                profile.state.lead.sortBy,
                profile.state.lead.sortDir
            );
            leads.setPerPage(profile.state.lead.perPage);
            leads.setFilters(profile.state.lead.filters[profile.state.env.brand || 0]);
            leads.setIncludes(['affiliate', 'sale', 'retention', 'country', 'campaigns', 'activity', 'brand']);

            if (route.params.id) {
                leads.onload.push(() => {
                    openLead(Number(route.params.id));
                });
            }
            reloadLeads();

            preloaderAligner('.environment');
        });

        onUnmounted(() => {
            document.removeEventListener('keydown', handleKeyPress);
        });

        function handleKeyPress(event) {
            if (event.key === 'ArrowUp') {
                navigateLead(-1);
            } else if (event.key === 'ArrowDown') {
                navigateLead(1);
            }
        }

        function navigateLead(direction) {
            const currentIndex = leads.state.findIndex(lead => lead.id.toString() === route.params.id);

            if (currentIndex !== -1) {
                const newIndex = (currentIndex + direction + leads.state.length) % leads.state.length;
                const newLeadId = leads.state[newIndex].id;

                openLead(newLeadId);
            }
        }

        function openMassUpdate() {
            PopUp
                .open(require('@lead/components/PopUps/MassUpdate.vue'))
                .setCaption(`Mass Update <span class="selected-count"> ( Selected leads: ${targets.value.length} )</span>`)
                .setPayload({
                    targets: targets.value
                })
                .setListeners({
                    updated: reloadLeads,
                })
                .onClose(() => {
                    targets.value = [];
                });
        }

        function checkAll() {
            if (targets.value.length < leads.state.length) {
                leads.state.forEach(lead => {
                    targets.value.push(lead.id.toString());
                });
            } else {
                targets.value = [];
            }
        }

        const handleCheckboxClick = (event, lead, index) => {
            if (event.shiftKey && lastSelectedIndex !== null) {
                const startIndex = Math.min(lastSelectedIndex, index);
                const endIndex = Math.max(lastSelectedIndex, index);

                const newTargets = leads.state
                .slice(startIndex, endIndex + 1)
                .map(lead => lead.id.toString());

                targets.value = [...new Set([...targets.value, ...newTargets])];
            } else {
                const leadId = lead.id.toString();
                if (!targets.value.includes(leadId)) {
                    targets.value.push(leadId);
                } else {
                    const targetIndex = targets.value.indexOf(leadId);
                    targets.value.splice(targetIndex, 1);
                }

                lastSelectedIndex = index;
            }
        };

        const openInNewTab = (id) => {
            window.open(`${route.params.locale ? `/${route.params.locale}` : ''}/leads/${id}`,'_blank');
        }

        const columns = ref([]);
        const addedCols = [
            {
                key: 'check_all',
                name: 'check_all',
                sortable: true,
                draggable: false,
            },
            {
                key: 'id',
                name: 'id',
                sortable: true,
                draggable: false,
            },
            {
                key: 'phone',
                name: 'phone',
                sortable: true,
                draggable: false,
            },
        ];

        const setColumns = () => {
            let leadCols = profile.state.lead?.columns?.length
                ? userState.value.columns.map(name => leads.columns.find(col => col.name === name) || {})
                : leads.columns;
            leadCols = leadCols.filter(column => column.name && (!column.allow || profile.permissions.includes(column.allow)));

            columns.value = [...addedCols, ...leadCols];
        }

        setColumns();

        const onCellMounted = (draggable, e) => {
            if (draggable === false) e.el.removeAttribute('data-draggable');
        }

        const onColumnDragEnd = () => {
            const colsNames = columns.value.filter(col => !addedCols.some(c => c.key === col.key)).map(col => col.name);

            if (colsNames.length > 0) {
                profile.state.lead.columns = colsNames;
                profile.saveState();
            }
        }

        return {
            targets,
            leads,
            columns,
            profile,
            PopUp,
            find,
            openLead,
            changePerPage,
            reloadLeads,
            paginate,
            sortBy,
            checkAll,
            handleCheckboxClick,
            openInNewTab,
            onCellMounted,
            onColumnDragEnd,
            openMassUpdate,
        };
    }
};
</script>

<style lang="scss" scoped>
.environment {
    position: relative;
    background-color: var(--env-bg, $env-bg);
}

.index {
    min-width: 100%;
    padding: 0 10px;
}

.row {
    fill: var(--main-text-color, $main-text-color);

    &:nth-child(odd) {
        background-color: var(--env-bg, $env-bg);
    }

    &:nth-child(even) {
        background-color: var(--bar-bg, $bar-bg);
    }

    &.header {
        background-color: var(--env-bg, $env-bg);
        box-shadow: 0 5px 20px -5px #00000055;
    }

    &:not(.header) {
        cursor: pointer;

        &.checked {
            color: black;

            :deep(.g-symbol) {
                fill: var(--success, $success) !important;
                z-index: 3;
            }

            & > .cell {
                background-color: var(--success-hover, $success-hover);
            }

            & > .cell.sortable {
                background-color: var(--success, $success);
            }
        }

        & > .cell {
            border: 0 solid transparent;
            border-width: 1px 0;
        }

        &:hover > .cell,
        &.open > .cell {
            border-color: var(--success, $success);
        }
    }
}

.cell {
    padding: 5px;
    white-space: nowrap;
    position: relative;

    &.title {
        cursor: default;

        .g-symbol {
            transition: transform 0.5s;
        }

        &.draggable {
            cursor: move;
        }
    }

    &.checkbox {
        font-size: 0;
        min-width: 46px;
        position: relative;
        background-color: transparent !important;

        :deep(.g-symbol) {
            z-index: 2;
            opacity: 0.99 !important;
            background-color: var(--env-bg, $env-bg) !important;
        }
    }

    &.check-all,
    &.id,
    &.is_ftd,
    &.is_test,
    &.email_confirmed,
    &.phone_confirmed,
    &.phone,
    &.language,
    &.is_autologin,
    &.created_at {
        padding: 10px 8px;
        text-align: center;
    }

    &.id {
        .g-symbol {
            &:hover {
                color: var(--primary, $primary);
            }
        }
    }

    &:not(.title) {
        &.commentDate {
            width: 250px;
            height: 48px;
            padding: 2px 0 0;

            & > fieldset {
                padding: 0;
                pointer-events: none;

                & > legend {
                    margin-left: 6px;
                    padding: 0 2px;
                    font-size: 0.9rem;
                    color: var(--secondary-hover, $secondary-hover);
                }

                & > div {
                    width: 250px;
                    padding: 0 8px 4px 8px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &:hover > fieldset {
                position: absolute;
                z-index: 2;
                bottom: 0;
                padding-top: 18px;
                font-size: 1rem;
                border: none;
                border-radius: 5px;
                background-color: var(--secondary, $secondary);

                & > legend {
                    top: 16px;
                    position: relative;
                    color: var(--tooltip-text-color, $tooltip-text-color);
                }

                & > div {
                    color: white;
                    white-space: normal;
                }
            }
        }
    }
}

.row:nth-child(-n+6) .cell:not(.title).commentDate:hover > fieldset {
    top: 0;
    bottom: auto;
}

.sortable {
    fill: var(--sorted-text-color, $sorted-text-color);
    color: var(--sorted-text-color, $sorted-text-color);
    background-color: var(--sorted-bg, $sorted-bg);

    &.title {
        font-weight: bold;

        &.desc .g-symbol {
            transform: rotate(180deg);
        }
    }
}

.check-all {
    cursor: pointer;
    fill: var(--main-text-color, $main-text-color);

    &:hover {
        fill: var(--title-color, $title-color);
    }
}

.g-pagination {
    padding: 16px 10px;
    position: fixed;
    bottom: 0;
    text-align: center;
    margin: 0 auto;
    left: calc(50% - 150px);
    right: 50%;
    transition: left 500ms, right 500ms;
    width: 400px;
}

.pagination-float-left {
    left: calc(50% - 640px);
}

.pagination-float-right {
    right: calc(50% - 270px);
}

.info-block {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 18px;
    color: var(--main-text-color, #aabfcf);
    background-color: var(--env-bg, #191d2b);
}

.bookmark {
    position: absolute;
    z-index: 2;
    top: 8%;
    left: 0;
    height: 86%;
    overflow: hidden;
    border-radius: 5px;
    width: calc(100% + 9px);
    clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%);

    & > span {
        flex-grow: 1;
        height: 100%;

        &.silver {
            background-image: linear-gradient(65deg, #b0b0bf, #f8f8ff);
        }

        &.gold {
            background-image: linear-gradient(65deg, #efa700, #ffef40);
        }

        &.green {
            background-image: linear-gradient(65deg, #12a555, #52e595);
        }

        &.cyan {
            background-image: linear-gradient(65deg, #008fcf, #50f5ff);
        }

        &.red {
            background-image: linear-gradient(65deg, #b01223, #e04354);
        }

        &.purple {
            background-image: linear-gradient(65deg, #700099, #d030ff);
        }
    }
}

.yes {
    color: var(--success-hover, $success-hover);
}

.no {
    color: var(--separator-color, $separator-color);
}

.filters-btn {
    height: 40px;
    color: var(--main-text-color, #aabfcf);
    margin: 0 2px 0 10px;
    fill: var(--main-text-color, #aabfcf);
    border: 1px solid var(--input-border, #4f5a69);
    position: relative;
    cursor: pointer;
    background-color: var(--env-bg, #191d2b);
    border-radius: var(--btn-rounding, $btn-rounding);
    padding: 0 10px;

    .g-symbol {
        margin-right: 4px;
    }

    &:hover {
        color: var(--title-color, $title-color);
        fill: var(--btn-primary-color, #ffffff);
        border-color: var(--primary, #0a45af);
        background-color: var(--primary, #0a45af);
    }
}

.mass-update-btn {
    font-size: 0;

    .g-symbol {
        margin-right: 0;
    }

    &:disabled {
        opacity: 1;
        filter: none;
    }

    &.is-active {
        color: var(--title-color, $title-color);
        fill: var(--btn-primary-color, #ffffff);
        border-color: var(--primary, #0a45af);
        background-color: var(--primary, #0a45af);
    }

    &:hover {
        &::after {
            white-space: nowrap;
            transform: translateX(-50%);
            top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
            left: 50%;
        }

        &::before {
            transform: translateX(-50%);
            left: 50%;
            top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
            border-bottom-color: var(--tooltip-bg, $tooltip-bg);
        }
    }
}

.index-bar {
    container-name: index-bar;
    container-type: inline-size;
}

@container index-bar (min-width: 960px) {
    .mass-update-btn {
        font-size: 14px;

        .g-symbol {
            margin-right: 4px;
        }

        &::before,
        &::after {
            display: none;
        }
    }
}

@media (min-width: 1025px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}

@media (max-width: 1024px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                padding: 8px 5px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}

@media (max-width: 984px) {
    .pagination-float-left {
        transform: translate(295px, 0);
    }
}
</style>