<script setup>

import { useLeadActions } from '@lead';
import { useFinanceActions } from '@finance';
import {reactive} from "vue";
import { useProfileActions } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getWithdrawResource = useFinanceActions('getWithdrawResource');
const withdraws = getWithdrawResource(
    { lead_id: [lead.model.id] },
    true,
    'LeadWithdraws Component'
);

const errors = reactive({});

const statuses = [
    { slug: 'new', name: 'New' },
    { slug: 'approved', name: 'Approved' },
    { slug: 'auto_approved', name: 'Auto approved' },
    { slug: 'processed', name: 'Processed' },
    { slug: 'error', name: 'Error' },
    { slug: 'canceled', name: 'Canceled' }
];

function changeStatus(id, value) {
    withdraws.save(id, {
        status: value
    }).catch(({ response }) => {
        if (response.status === 403) {
            errors[id] = 'This action is unauthorized';
        }
    });
}

</script>

<template>
    <div class="withdraws">
        <g-preloader v-if="withdraws.isLoading"/>
        <g-caption class="info" weight="500" size="4" v-if="!withdraws.state.length && !withdraws.isLoading"><div>{{$t('base.no_withdraws')}}</div></g-caption>
        <template v-if="!withdraws.isLoading">
        <div v-for="withdraw in withdraws.state" v-bind:key="`deposit-${withdraw.id}`" class="withdraw"
             v-bind:class="withdraw.method">

            <!-- METHOD -->
            <g-flex class="method" align="center" gap="5">
                <span class="title">{{ $t('finances.method', 'Method') }}:</span>
                <span class="value">{{ withdraw.method }}</span>
            </g-flex>
            <!--/ METHOD -->

            <!-- AMOUNT -->
            <div class="amount">
                <div class="title">{{ $t('finances.amount', 'Amount') }}:</div>
                <span class="value">
					{{ Number((withdraw.amount / withdraw.rate).toFixed(5)) }}
					<small class="currency" v-bind:class="withdraw.currency.toLowerCase()">{{
                            withdraw.currency
                        }}</small>
				</span>
            </div>
            <!--/ AMOUNT -->

            <!-- APPROVED BY -->
            <div v-if="withdraw.user">
                <div class="title">{{ $t('finances.edited_by', 'Edited by') }}:</div>
                <span class="value edit-by">{{ withdraw.user.name }}</span>
            </div>
            <div v-else></div>
            <!--/ APPROVED BY -->

            <!-- STATUS -->
            <div v-if="withdraw.status === 'processed'" class="status" v-bind:class="withdraw.status">
                <div class="title">{{ $t('finances.status', 'Status') }}:</div>
                <span class="value">{{ withdraw.status }}</span>
            </div>
            <div v-else class="status" v-bind:class="withdraw.status">
                <g-select v-model="withdraw.status"
                          label="Status"
                          v-bind:options="statuses"
                          option-text="name"
                          option-value="slug"
                          v-on:select="changeStatus(withdraw.id, $event)"
                          v-bind:error="errors[withdraw.id]"
                          v-bind:readonly="profile.cant('Withdraw Edit')"/>
            </div>
            <!--/ STATUS -->

            <!-- DATE -->
            <div class="date">
                <div class="title">{{ $t('finances.created_at', 'Created at') }}:</div>
                <span class="value">{{ withdraw.created_at }}</span>
            </div>
            <!--/ DATE -->

            <!-- USDT AMOUNT -->
            <div v-if="withdraw.method === 'crypto' && withdraw.currency !== 'USDT'" class="usdt_amount">
                <div class="title">{{ $t('finances.usdt_amount', 'USDT Amount') }}:</div>
                <span class="value">{{ Number(withdraw.amount).toFixed(2) }} <small
                    class="currency usdt">USDT</small></span>
            </div>
            <!--/ USDT AMOUNT -->

            <!-- TYPE -->
            <!--
            <div class="type">
                <div class="title">Type:</div>
                <span class="value">{{ withdraw.type }}</span>
            </div>
            -->
            <!--/ TYPE -->

            <!-- ADDRESS -->
            <div v-if="withdraw.method === 'crypto'" class="address">
                <div class="title">{{ $t('finances.to_address', 'To address') }}</div>
                <g-copy class="value" v-bind:text="withdraw.address"></g-copy>
            </div>
            <div v-else-if="withdraw.method === 'paypal'" class="paypal_email">
                <div class="title">{{ $t('finances.paypal_email', 'PayPal Email') }}</div>
                <g-copy class="value" v-bind:text="withdraw.details?.paypal_email || 'n/a'"></g-copy>
            </div>
            <!--/ ADDRESS -->

            <template v-if="withdraw.method === 'card'">
                <div class="card_name">
                    <div class="title">{{ $t('finance.card_name', 'Card name') }}</div>
                    <div class="value">{{ withdraw.details?.card_name || 'n/a' }}</div>
                </div>
                <div class="card_number">
                    <div class="title">{{ $t('finances.card_number', 'Card number') }}</div>
                    <g-copy class="value" v-bind:text="withdraw.details?.card_number || 'n/a'"></g-copy>
                </div>
                <div class="cvv">
                    <div class="title">CVV</div>
                    <div class="value">{{ withdraw.details?.cvv || 'n/a' }}</div>
                </div>
                <div class="exp_date">
                    <div class="title">{{ $t('finance.exp_date', 'Exp. date') }}</div>
                    <div class="value">{{ withdraw.details?.exp_date || 'n/a' }}</div>
                </div>
            </template>

            <!-- DETAILS -->
            <div class="details" v-if="withdraw.method === 'bank'">
                <label v-bind:for="`w-${withdraw.id}`" class="title">
                    {{ $t('finances.details', 'Details') }}
                    <g-symbol name="arrow-down" width="18" height="18"/>
                </label>
                <input type="checkbox" v-bind:id="`w-${withdraw.id}`" hidden/>
                <div class="container">
                    <div class="beneficiary">
                        <div class="title">Beneficiary</div>
                        <div class="value">{{ withdraw.details?.beneficiary || 'n/a' }}</div>
                    </div>
                    <div class="beneficiary_number">
                        <div class="title">Beneficiary Number</div>
                        <div class="value">{{ withdraw.details?.beneficiary_number || 'n/a' }}</div>
                    </div>
                    <div class="beneficiary_address">
                        <div class="title">Beneficiary Address</div>
                        <div class="value">{{ withdraw.details?.beneficiary_address || 'n/a' }}</div>
                    </div>
                    <div class="beneficiary_bank">
                        <div class="title">Beneficiary Bank</div>
                        <div class="value">{{ withdraw.details?.beneficiary_bank || 'n/a' }}</div>
                    </div>
                    <div class="bank_country">
                        <div class="title">Bank Country</div>
                        <div class="value">{{ withdraw.details?.bank_country || 'n/a' }}</div>
                    </div>
                    <div class="bank_address">
                        <div class="title">Bank Address</div>
                        <div class="value">{{ withdraw.details?.bank_address || 'n/a' }}</div>
                    </div>
                    <div class="swift">
                        <div class="title">SWIFT/BIC</div>
                        <g-copy class="value" v-bind:text="withdraw.details?.swift || 'n/a'"></g-copy>
                    </div>
                </div>
            </div>
            <!--/ DETAILS -->

            <!-- REASON -->
            <fieldset class="reason" v-if="withdraw.reason">
                <legend>{{ $t('finances.reference', 'Reference') }}</legend>
                {{ withdraw.reason }}
            </fieldset>
            <!--/ REASON -->

        </div>
        </template>
    </div>
</template>

<style scoped lang="scss">
.withdraws {
    & > .withdraw {
        display: grid;
        grid-row-gap: 10px;
        grid-column-gap: 12px;
        grid-template-rows: 32px 1fr auto;
        grid-template-columns: 0.9fr 1.1fr 120px 150px;
        margin-bottom: 10px;
        padding: 18px 20px 22px 20px;
        position: relative;
        color: var(--title-color, $title-color);
        border-radius: var(--bar-rounding, $bar-rounding);
        background-color: var(--bar-bg, $bar-bg);
        box-shadow: 0 5px 15px -5px #000000aa;

        & > .method {
            grid-column: 1/5;
            text-transform: capitalize;
        }

        & > .status {
            text-transform: capitalize;

            &.new > .g-select:deep(fieldset) {
                border-color: var(--primary-hover, $primary-hover);

                & > legend {
                    color: var(--primary-hover, $primary-hover);
                }
            }

            &.approved > .g-select:deep(fieldset),
            &.auto_approved > .g-select:deep(fieldset) {
                border-color: var(--warning-hover, $warning-hover);

                & > legend {
                    color: var(--warning-hover, $warning-hover);
                }
            }

            &.processed > .value {
                color: var(--success-hover, $success-hover);
            }

            &.error > .g-select:deep(fieldset) {
                border-color: var(--danger-hover, $danger-hover);

                & > legend {
                    color: var(--danger-hover, $danger-hover);
                }
            }

            &.canceled > .g-select:deep(fieldset) {
                border-color: var(--separator-color, $separator-color);

                & > legend {
                    color: var(--disabled-color, $disabled-color);
                }
            }
        }

        & > .address {
            grid-column: 3/5;
        }

        & > .paypal_email {
            grid-column: 1/3;
        }

        & > .details {
            grid-column: 1/5;

            & > .title {
                gap: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                height: 30px;
                text-align: center;
                margin-bottom: 10px;
                border: 1px solid var(--separator-color, $separator-color);
                fill: var(--main-text-color, $main-text-color);
            }
        }

        .title {
            font-size: 0.9rem;
            white-space: nowrap;
            color: var(--main-text-color, $main-text-color);
        }

        .value {
            white-space: nowrap;
        }

        .edit-by {
            display: inline-block;
            max-width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.details {
    & > .container {
        transition: max-height 0.3s;
        overflow: hidden;
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        & > .swift {
            grid-column: 3/5;
        }
    }

    & > input:not(:checked) + .container {
        max-height: 0;
    }

    & > input:checked + .container {
        max-height: 300px;
    }
}

.reason {
    grid-column: 1/5;
    padding: 8px 15px;
    border: 1px solid var(--separator-color, $separator-color);
    border-radius: var(--bar-rounding, $bar-rounding);
    background-color: var(--env-bg, $env-bg);
}

.currency {
    font-size: 0.8rem;
}

.card {
    border: 1px solid #f2dc9d;

    & > .method > .value {
        color: #f2dc9d;
    }
}

.bank {
    border: 1px solid #4fa58c;

    & > .method > .value {
        color: #4fa58c;
    }
}

.paypal {
    border: 1px solid #5d80e5;

    & > .method > .value {
        color: #5d80e5;
    }
}

.btc {
    color: #f2dc9d;
}

.usdt {
    color: #4fa58c;
}

.eth {
    color: #5d80e5;
}

.info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 18px;
    margin-top: 50%;

    & > div {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
}
</style>