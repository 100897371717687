import { STATE } from '@/Ship';
import { useAuthTasks } from '@auth';
import Router from '../router';

const deleteToken = useAuthTasks('deleteToken');

export default {
    apiErrors: {
        401: error => {
            deleteToken();
            STATE.IS_LOGIN = false;
            console.error(`ERROR: ${error.message}`);
            Router.push({name: 'SignIn'});
        },

        422: error => {
            console.error(`ERROR: ${error.message}`);
        },

        500: error => {
            console.error(`ERROR: ${error.message}`);
        },

        default: {}
    },

    webSocketErrors: {
        default: {}
    }

};
