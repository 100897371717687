<script setup>
import { computed } from 'vue';
import { useNotificationsAtions, useNotificationsResources } from '@notifications';
import { useProfileActions } from '@profile';
import { useLeadActions } from '@lead';
import { useShipActions, PopUpManager as PopUp } from '@/Ship';
import { useRoute, useRouter } from 'vue-router';

const { markAs, getNotificationTypeName } = useNotificationsAtions();
const { timeFromNow, formatDate } = useShipActions();
const getProfileResource = useProfileActions('getProfileResource');
const getLeadResource = useLeadActions('getLeadResource');
const profile = getProfileResource();
const leads = getLeadResource();
const notifications = useNotificationsResources('Notifications');

const router = useRouter();
const route = useRoute();

const props = defineProps({
    notificationId: {
        type: String,
        required: true,
    }
});

const notification = notifications.state.find(n => n.id === props.notificationId);
const type = getNotificationTypeName(notification?.data.type_id);

const isWithdrawCreated = computed(() => {
    return !!notification?.data.body?.method;
})

const onMarkClick = async () => {
    notification?.read_at ?
        await markAs(notification, 'unread') :
        await markAs(notification, 'read');
}

const onLinkButtonClick = () => {
    if (isWithdrawCreated.value) {
        profile.state.lead.tab = 'Finances';
        profile.state.lead.subtab = 'LeadWithdraws';
        profile.saveState();
    }

    const id = notification?.data.lead_id;
    if (!id) {
        console.error("Couldn't get lead id.");
        return;
    }
    leads.show(id, { include: 'campaigns,customer,activity' }).then(() => {
        leads.index = leads.state.findIndex(item => item.id === id);
    });
    router
        .push({ name: 'Lead', params: { ...route.params, id } })
        .then(() => PopUp.close());
}
</script>

<template>
    <div v-if="notification" class="notification" v-bind:data-type="type">
        <g-preloader-content v-bind:is-loading="notifications.isLoading" />
        <div class="top">
            <span>{{ timeFromNow(notification.created_at) }}</span>
            <button
                type="button"
                class="mark"
                v-bind:class="{ 'is-unread': !notification.read_at }"
                v-on:click="onMarkClick"
                v-bind:disabled="notifications.isLoading"
            >
                <g-symbol name="double-check" width="19" height="11" />
                {{ $t(`notifications.${notification.read_at ? 'mark_as_unread' : 'mark_as_read'}`) }}
            </button>
        </div>

        <div class="tag">{{ $t(`base.${type}`) }}</div>

        <div class="body">
            <h4>{{ notification.data.title }}</h4>
            
            <ul v-if="isWithdrawCreated">
                <li><strong>{{ $t('base.method') }}</strong>: {{ notification.data.body.method }}</li>
                <li><strong>{{ $t('base.amount') }}</strong>: {{ notification.data.body.amount }}</li>
                <li><strong>{{ $t('base.address') }}</strong>: <g-copy symbol="copy-2" v-bind:text="notification.data.body.address" /></li>
                <li><strong>{{ $t('base.date') }}</strong>: {{ formatDate(notification.data.body.date) }}</li>
            </ul>
            <p v-else-if="typeof notification.data.body === 'string'">{{ notification.data.body }}</p>
        </div>

        <button
            v-if="notification.data.lead_id"
            class="footer-btn"
            v-on:click="onLinkButtonClick"
            type="button"
        >
            {{ notification.data.body?.method ? 'View Lead Withdraws' : 'View Lead' }}
            <g-symbol name="redirect" width="12" height="12" />
        </button>
    </div>
</template>

<style lang="scss" scoped>
.notification {
    color: var(--notifications-color, $notifications-color);

    > .top {
        margin-bottom: 17px;
    }

    > .tag {
        margin-bottom: 14px;
    }

    &[data-type='important'] {
        --tag-background-color: var(--notifications-type-color-important, $notifications-type-color-important);
    }

    &[data-type='main'] {
        --tag-background-color: var(--notifications-type-color-main, $notifications-type-color-main);
    }

    &[data-type='info'] {
        --tag-background-color: var(--notifications-type-color-info, $notifications-type-color-info);
    }
}

.top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 12px;
    color: var(--notifications-title-color, $notifications-title-color);
    padding-right: 64px;
}

.mark {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    column-gap: 5px;
    font: inherit;
    color: inherit;
    background-color: transparent;
    padding: 0;

    > .g-symbol {
        fill: currentColor;
    }

    &.is-unread {
        color: var(--main-text-color, $main-text-color);
    }
}

.tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    padding: 3px 12px 4px;
    border-radius: 10px;
    background-color:
        color-mix(
            in srgb,
            var(--tag-background-color, var(--notifications-type-color-main, $notifications-type-color-main)) 30%,
            transparent
        );
}

.body {
    margin-bottom: 27px;

    > h4 {
        font-size: 20px;
        font-weight: 700;
    }

    > p,
    > ul {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    > ul {
        list-style-type: none;
    }

    &:deep .g-copy {
        .g-symbol {
            width: 21px;
            height: 21px;
            padding: 5px;
            border-radius: 50%;
            fill: var(--notifications-copy-color, $notifications-copy-color);
            background-color: var(--notifications-copy-background-color, $notifications-copy-background-color);
        }
    }
}

.footer-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 30px;
    height: 69px;
    background-color: var(--notifications-footer-background-color, $notifications-footer-background-color);
    border-top: 1px solid var(--notifications-footer-border-color, $notifications-footer-border-color);
    color: var(--notifications-footer-color, $notifications-footer-color);
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-left: -32px;
    margin-right: -32px;
    width: calc(100% + 64px);
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;

    > .g-symbol {
        fill: currentColor;
    }
}
</style>
