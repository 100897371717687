
import _ from 'lodash';
import { useSettingsResources } from '@settings';
import { ResourcesManager } from "@/Ship";

export default (section = null) => {
	const settings = useSettingsResources('Settings');

	const loaded = [...new Set([...settings.loaded.value, ...settings.loading.value])];

	if (section) {
		if (Array.isArray(section)) {
			const sections = _.difference(section, loaded);
			if (sections.length) {
				settings.load(null, {
					sections: sections.join()
				});
			}
		} else if (!settings.loaded.value.includes(section) && !settings.loading.value.includes(section)) {
			settings.load(section);
		}
	} else {
		const sections = _.difference([
			'general',
			'trading',
			'roles',
			'permissions',
			'all-permissions',
			'statuses',
			'account-levels',
		], loaded);

		if (sections.length) {
			settings.load(null, {
				sections: sections.join()
			});
		}
	}

	ResourcesManager.addResource(settings, 'Settings');

	return settings;
};
