<script setup>

import _ from "lodash";
import { computed, ref, reactive } from "vue";
import { useProfileActions } from '@profile';
import { useLeadActions } from '@lead';
import { useUserActions } from '@user';
import { STATE, i18n } from '@/Ship';
import { useSettingsActions } from '@settings';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource(['account-levels','statuses']);

const {
    getSales,
    getRetentions,
} = useUserActions();

const sales = ref([]);
getSales().then(response => {
    sales.value = response.data;
});

const retentions = ref([]);
getRetentions().then(response => {
    retentions.value = response.data;
    if (lead.model.retention_id) {
        retentions.value.push({
            id: null,
            name: 'None',
        });
    }
});

const payload = reactive({});
const state = reactive({});

const errors = reactive({});

setState();

function addFieldToChangeList(field) {
    if (!STATE.UNSAVED_DATA.condition) {
        STATE.UNSAVED_DATA.condition = [];
    }
    if (!STATE.UNSAVED_DATA.condition.includes(field)) {
        STATE.UNSAVED_DATA.condition.push(field);
    }
}

const model = new Proxy(payload, {
    get: (target, key) => {
        return target[key] || state[key];
    },
    set: (target, key, value) => {
        if (value === state[key]) {
            delete payload[key];
            STATE.UNSAVED_DATA.condition.splice(STATE.UNSAVED_DATA.condition.indexOf(key), 1);
        } else {
            if (value === null) {
                delete state[key];
            }
            target[key] = value;
            addFieldToChangeList(i18n.global.t(`lead.${key}`, key));
        }

        return true;
    }
});

function setState() {
    [
        'conversion_status',
        'affiliate_status',
        'retention_status',
        'kyc_status',
        'sale_id',
        'retention_id',
        'is_ftd',
        'balance',
        'account_level',
        'label'
    ].forEach(key => state[key] = lead.model[key]);
}

function save() {
    lead.save(lead.model.id, payload).then(({ refusal }) => {

        Object.keys(refusal).forEach(key => {
            delete payload[key];
            errors[key] = refusal[key];
        });

        Object.assign(state, payload);
        STATE.UNSAVED_DATA.condition = [];
        Object.keys(payload).forEach(key => {
            switch (key) {
                case 'retention_id':
                    const retention = retentions.value.find(retention => retention.id === payload.retention_id);
                    if (retention) {
                        lead.state[lead.index].retention = retention.name;
                    }
                    break;
                case 'sale_id':
                    const sale = sales.value.find(sale => sale.id === payload.sale_id);
                    if (sale) {
                        lead.state[lead.index].sale = sale.name;
                    }
                    break;
                default:
                    lead.state[lead.index][key] = state[key];
                    break;
            }
            delete payload[key];
        });
    });
}

function reset() {
    Object.keys(payload).forEach(key => delete payload[key]);
    STATE.UNSAVED_DATA.condition = [];
}

const statuses = computed(() => settings.statuses || []);

const labels = ref([
    { n: 'Silver', v: 'silver' },
    { n: 'Gold', v: 'gold' },
    { n: 'Green', v: 'green' },
    { n: 'Red', v: 'red' },
    { n: 'Cyan', v: 'cyan' },
    { n: 'Purple', v: 'purple' }
]);

const bookmarks = computed({
    get() {
        return !!model.label
            ? model.label.split(',')
            : [];
    },
    set(value) {
        model.label = value.join();
        return true;
    }
});

const conversionStatuses = computed(() => {
    const clonedQuotes = _.cloneDeep(statuses.value.filter(status => status.target === 'conversion'));
    if (!statuses.value.find((status) => lead.model.conversion_status === status.name && status.target === 'conversion')) {
        clonedQuotes.push({
            name: lead.model.conversion_status,
            target: 'conversion',
        });
    }

    return clonedQuotes;
});

const affiliateStatuses = computed(() => {
    return conversionStatuses.value;
})

const retentionStatuses = computed(() => {
    const clonedQuotes = _.cloneDeep(statuses.value.filter(status => status.target === 'retention'));
    if (!statuses.value.find((status) => lead.model.retention_status === status.name && status.target === 'retention')) {
        clonedQuotes.push({
            name: lead.model.retention_status,
            target: 'retention',
        });
    }

    return clonedQuotes;
});

const kycStatuses = computed(() => {
    const clonedQuotes = _.cloneDeep(statuses.value.filter(status => status.target === 'kyc'));
    if (!statuses.value.find((status) => lead.model.kyc_status === status.name && status.target === 'kyc')) {
        clonedQuotes.push({
            name: lead.model.kyc_status,
            target: 'kyc',
        });
    }

    return clonedQuotes;
});

const accountLevels = computed(() => {
    return settings.model['account-levels']//[lead.model.brand]
        ? Object.keys(settings.model['account-levels'][lead.model.brand]).map(level => {
            return {v: level}
        })
        : [];
});
</script>

<template>
    <div>
        <g-select v-if="profile.can('Lead ConversionStatusView')"
                  v-model="model.conversion_status"
                  v-bind:backlight="!!payload.conversion_status"
                  option-text="name"
                  option-value="name"
                  v-bind:options="conversionStatuses"
                  v-bind:label="$t('lead.conversion_status')"
                  v-bind:readonly="profile.cant('Lead ConversionStatusEdit')"
                  v-bind:error="errors.conversion_status"/>
    </div>
    <div>
        <g-select v-if="profile.can('Lead AffiliateStatusView')"
                  v-model="model.affiliate_status"
                  v-bind:backlight="!!payload.affiliate_status"
                  option-text="name"
                  option-value="name"
                  v-bind:options="affiliateStatuses"
                  v-bind:label="$t('lead.affiliate_status', 'Affiliate Status')"
                  v-bind:readonly="profile.cant('Lead AffiliateStatusEdit')"
                  v-bind:error="errors.affiliate_status"/>
    </div>
    <div>
        <g-select v-if="profile.can('Lead RetentionStatusView')"
                  v-model="model.retention_status"
                  v-bind:backlight="!!payload.retention_status"
                  option-text="name"
                  option-value="name"
                  v-bind:options="retentionStatuses"
                  v-bind:label="$t('lead.retention_status', 'Retention Status')"
                  v-bind:readonly="profile.cant('Lead RetentionStatusEdit')"
                  v-bind:error="errors.retention_status"/>
    </div>
    <div>
        <g-select v-if="profile.can('Lead KycStatusView')"
                  v-model="model.kyc_status"
                  v-bind:backlight="!!payload.kyc_status"
                  v-bind:options="kycStatuses"
                  option-text="name"
                  option-value="name"
                  v-bind:label="$t('lead.kyc_status', 'KYC Status')"
                  v-bind:readonly="profile.cant('Lead KYCStatusEdit')"
                  v-bind:error="errors.kyc_status"/>
    </div>

    <div>
        <g-select v-if="profile.can('Lead SaleView')"
                  v-model="model.sale_id"
                  v-bind:backlight="!!payload.sale_id"
                  v-bind:options="sales"
                  option-text="name"
                  option-value="id"
                  v-bind:label="$t('lead.sale')"
                  v-bind:readonly="profile.cant('Lead SaleEdit')"/>
    </div>
    <div>
        <g-select v-if="profile.can('Lead RetentionView')"
                  v-model="model.retention_id"
                  v-bind:backlight="!!payload.retention_id"
                  v-bind:options="retentions"
                  option-text="name"
                  option-value="id"
                  v-bind:label="$t('lead.retention')"
                  v-bind:readonly="profile.cant('Lead RetentionEdit') || model.is_ftd === 'No'"/>
    </div>

    <div>
        <g-select v-if="profile.can('Lead IsFtdView')"
                  v-model="model.is_ftd"
                  v-bind:backlight="!!payload.is_ftd"
                  v-bind:options="[{value: 'No'}, {value: 'Yes'}]"
                  option-text="value"
                  option-value="value"
                  v-bind:label="$t('lead.is_ftd')"
                  v-bind:readonly="profile.cant('Lead IsFtdEdit')"
                  v-bind:error="errors.is_ftd"/>
    </div>
    <div v-if="profile.can('Lead BalanceView')" class="balance">
        <g-field v-model="model.balance"
                 v-bind:backlight="!!payload.balance"
                 v-bind:label="$t('lead.balance')"
                 v-bind:readonly="profile.cant('Lead BalanceEdit')"
                 v-bind:error="errors.balance">
            <g-symbol v-if="profile.cant('Lead BalanceEdit')" name="lock" width="18" height="18"/>
        </g-field>
    </div>

    <div>
        <g-select v-if="profile.can('Lead AccountLevelView')"
                  v-model="model.account_level"
                  v-bind:backlight="!!payload.account_level"
                  v-bind:options="accountLevels"
                  option-text="v"
                  option-value="v"
                  v-bind:label="$t('lead.account_level')"
                  v-bind:readonly="profile.cant('Lead AccountLevelEdit')"
                  v-bind:error="errors.account_level"/>
    </div>

    <div>

        <g-fade-container class="bookmarks"
                          icon="label"
                          v-bind:label="model.label.split(',').join(', ') || 'Label'">
            <div class="labels">
                <g-checkbox v-for="label in labels"
                            v-model="bookmarks"
                            v-bind:value="label.v"
                            v-bind:key="label.v"
                            v-bind:class="label.v"
                            v-bind:disabled="profile.cant('Lead LabelEdit')">
                    {{ label.n }}
                </g-checkbox>
            </div>
        </g-fade-container>
    </div>

    <g-flex justify="end" class="btn-bar full-row" gap="8">
        <g-button class="reset-btn" type="button" v-on:click="reset" v-bind:disabled="!Object.keys(payload).length">
            <g-symbol name="refresh" width="18" height="18"/>
            {{ $t('base.reset', 'Reset') }}
        </g-button>
        <g-button class="save-btn" type="button" v-on:click="save" v-bind:disabled="!Object.keys(payload).length">
            <g-symbol name="save" width="18" height="18"/>
            {{ $t('base.save', 'Save') }}
        </g-button>
    </g-flex>

    <!--
    <div v-if="profile.can('Lead PinedWalletView')" class="full-row">
        <g-field v-model="specifics.wallets"
                 v-bind:label="$t('lead.wallet', 'Wallet')"
                 v-bind:readonly="profile.cant('Lead PinedWalletEdit')"/>
    </div>
    -->
</template>

<style scoped lang="scss">
.full-row {
    grid-column: 1/3;
}

.btn-bar {
    padding-top: 20px;
}

.btn-bar > .g-button {
    padding: 0 30px;
    min-width: 130px;
    font-size: 15px !important;

    &.reset-btn {
        fill: var(--main-text-color, $main-text-color);
        color: var(--main-text-color, $main-text-color);
        background-color: transparent;
        border: 1px solid var(--separator-color, $separator-color);

        &:hover {
            color: white;
            fill: white;
            background-color: var(--separator-color, $separator-color);
        }
    }

    &.save-btn {
        background-color: var(--primary, $primary);

        &:hover {
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    & > .g-symbol {
        margin-right: 4px;
    }
}

.edit-btn {
    width: 45px;
    position: relative;
    color: var(--title-color, $title-color);
    background-color: var(--env-bg, $env-bg);
    fill: var(--success, $success);
    border: 1px solid var(--success, $success);

    &::after {
        left: 20%;
        transform: translateX(-50%);
        top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &::before {
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
        border-bottom-color: var(--tooltip-bg, $tooltip-bg);
    }

    &:hover {
        background-color: var(--success, $success);
        fill: var(--btn-primary-color, $btn-primary-color);
    }
}

.bookmarks {
    &:deep(.value) {
        flex-grow: 1;
        text-align: center;
        text-transform: capitalize;
    }
}

.labels {
    column-count: 2;
    padding: 16px;
    border-radius: 9px;
    background-color: var(--env-bg, $env-bg);
    border: 1px solid var(--separator-color, $separator-color);

    & > .g-checkbox {
        width: 100%;
        margin: 5px 0;
        display: inline-block;

        &:hover {
            color: var(--title-text-color, $title-text-color);
        }
    }
}
</style>