<script setup>

import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useProfileActions } from '@profile';
import { useCampaignActions, useCampaignComponents } from '@campaign';
import { preloaderAligner } from '@/Ship/components/global/preloaders/scrollHellper';

const getProfileResource = useProfileActions('getProfileResource');
const getCampaignResource = useCampaignActions('getCampaignResource');
const Indexbar = useCampaignComponents('Indexbar');

const campaigns = getCampaignResource(false);
const profile = getProfileResource();
const router = useRouter();
const route = useRoute();

function openCampaign(id) {
    campaigns.index = campaigns.state.findIndex(item => item.id === id);
    router.push({ name: 'Campaign', params: { ...route.params, id } });
}

function sortBy(field) {
    if (campaigns.sortBy === field) {
        profile.state.campaign.sortDir = campaigns.sortDir === 'asc'
            ? 'desc'
            : 'asc';
    }
    profile.state.campaign.sortBy = field;
    profile.saveState();

    campaigns.sort(
        profile.state.campaign.sortBy,
        profile.state.campaign.sortDir
    );
    campaigns.load();
}

onMounted(() => {
    campaigns.setIncludes(['offer', 'buyer.teamlead', 'countries']);

    campaigns.sort(
        profile.state.campaign.sortBy,
        profile.state.campaign.sortDir
    );
    //campaigns.setPerPage(profile.state.campaign.perPage);
    campaigns.load().then(() => {
        if (route.params.id) {
            openCampaign(Number(route.params.id));
        }
    });

    preloaderAligner('.environment');
});
</script>

<template>
    <div class="offers environment">
        <indexbar>
            </indexbar>
            <div class="index">
                <div class="row header">
                    <div class="id title cell" v-bind:class="{[`sorted ${campaigns.sortDir}`]:  campaigns.sortBy === 'id' }">
                        <g-flex align="center" justify="center" gap="2" v-on:click="sortBy('id')">
                            ID
                            <g-symbol name="arrow-down" width="14" height="14"/>
                        </g-flex>
                    </div>

                    <div class="name title cell" v-bind:class="{[`sorted ${campaigns.sortDir}`]:  campaigns.sortBy === 'name' }">
                        <g-flex align="center" gap="2" v-on:click="sortBy('name')">
                            {{ $t('campaign.campaign') }}
                            <g-symbol name="arrow-down" width="14" height="14"/>
                        </g-flex>
                    </div>

                    <div class="offer title cell" v-bind:class="{[`sorted ${campaigns.sortDir}`]:  campaigns.sortBy === 'offer_id' }">
                        <g-flex align="center" gap="2" v-on:click="sortBy('offer_id')">
                            {{ $t('campaign.offer') }}
                            <g-symbol name="arrow-down" width="14" height="14"/>
                        </g-flex>
                    </div>

                    <div class="source title cell" v-bind:class="{[`sorted ${campaigns.sortDir}`]:  campaigns.sortBy === 'source' }">
                        <g-flex align="center" gap="2" v-on:click="sortBy('source')">
                            {{ $t('campaign.source') }}
                            <g-symbol name="arrow-down" width="14" height="14"/>
                        </g-flex>
                    </div>

                    <div class="affiliate title cell" v-bind:class="{[`sorted ${campaigns.sortDir}`]:  campaigns.sortBy === 'affiliate_id' }">
                        <g-flex align="center" gap="2" v-on:click="sortBy('affiliate_id')">
                            {{ $t('campaign.affiliate') }}
                            <g-symbol name="arrow-down" width="14" height="14"/>
                        </g-flex>
                    </div>

                    <div v-if="profile.can('Buyer View')" class="buyer title cell" v-bind:class="{[`sorted ${campaigns.sortDir}`]:  campaigns.sortBy === 'buyer_id' }">
                        <g-flex align="center" gap="2" v-on:click="sortBy('buyer_id')">
                            {{ $t('campaign.buyer') }}
                            <g-symbol name="arrow-down" width="14" height="14"/>
                        </g-flex>
                    </div>

                    <div class="geo title cell" v-bind:class="{[`sorted ${campaigns.sortDir}`]:  campaigns.sortBy === 'geo' }">
                        <g-flex align="center" gap="2" v-on:click="sortBy('geo')">
                            {{ $t('campaign.geo') }}
                            <g-symbol name="arrow-down" width="14" height="14"/>
                        </g-flex>
                    </div>

                    <div class="language title cell" v-bind:class="{[`sorted ${campaigns.sortDir}`]:  campaigns.sortBy === 'language' }">
                        <g-flex align="center" justify="center" gap="2" v-on:click="sortBy('language')">
                            {{ $t('base.language') }}
                            <g-symbol name="arrow-down" width="14" height="14"/>
                        </g-flex>
                    </div>

                    <div class="leads title cell" v-bind:class="{[`sorted ${campaigns.sortDir}`]:  campaigns.sortBy === 'leads_count' }">
                        <g-flex align="center" justify="center" gap="2" v-on:click="sortBy('leads_count')">
                            {{ $t('campaign.leads') }}
                            <g-symbol name="arrow-down" width="14" height="14"/>
                        </g-flex>
                    </div>
                    <div class="date title cell" v-bind:class="{[`sorted ${campaigns.sortDir}`]:  campaigns.sortBy === 'created_at' }">
                        <g-flex align="center" justify="center" gap="2" v-on:click="sortBy('created_at')">
                            {{ $t('base.created_at') }}
                            <g-symbol name="arrow-down" width="14" height="14"/>
                        </g-flex>
                    </div>
                </div>

                <g-preloader-content :is-loading="campaigns.isLoading"/>

                <div class="row"
                     v-for="campaign in campaigns.state"
                     v-bind:key="`campaign-${campaign.id}`"
                     v-on:click="openCampaign(campaign.id)"
                     v-bind:class="{'open': $route.params.id === campaign.id.toString()}">

                    <div class="id cell" v-bind:class="{ sorted: campaigns.sortBy === 'id' }">
                        {{ campaign.id }}
                    </div>
                    <div class="name cell" v-bind:class="{ sorted: campaigns.sortBy === 'name' }">
                        {{ campaign.name }}
                    </div>
                    <div class="offer cell" v-bind:class="{ sorted: campaigns.sortBy === 'offer_id' }">
                        {{ campaign.offer.name }}
                    </div>
                    <div class="source cell" v-bind:class="{ sorted: campaigns.sortBy === 'source' }">
                        {{ campaign.source }}
                    </div>
                    <div class="affiliate cell" v-bind:class="{ sorted: campaigns.sortBy === 'affiliate_id' }">
                        {{ campaign.buyer.teamlead.name }}
                    </div>
                    <div v-if="profile.can('Buyer View')" class="buyer cell" v-bind:class="{ sorted: campaigns.sortBy === 'buyer_id' }">
                        {{ campaign.buyer.name }}
                    </div>
                    <div class="geo cell" v-bind:class="{ sorted: campaigns.sortBy === 'geo' }">
                        {{ campaign.geo.join(', ') }}
                    </div>
                    <div class="language cell" v-bind:class="{ sorted: campaigns.sortBy === 'language' }">
                        {{ campaign.language }}
                    </div>
                    <div class="leads cell" v-bind:class="{ sorted: campaigns.sortBy === 'leads_count' }">
                        {{ campaign.leads_count }}
                    </div>
                    <div class="date cell" v-bind:class="{ sorted: campaigns.sortBy === 'created_at' }">
                        {{ campaign.created_at }}
                    </div>
                </div>
                <div class="info-block" v-if="!campaigns.state.length && !campaigns.isLoading">
                    {{ $t('base.nothing_found') }}
                </div>
            </div>
    </div>
</template>

<style lang="scss" scoped>
.environment {
    position: relative;
    background-color: var(--env-bg, $env-bg);
    height: 100%;
}

.index {
    min-width: 100%;
    padding: 0 10px;
}

.row {
    fill: var(--main-text-color, $main-text-color);

    &:nth-child(odd) {
        background-color: var(--env-bg, $env-bg);
    }

    &:nth-child(even) {
        background-color: var(--bar-bg, $bar-bg);
    }

    &:not(.header) {
        cursor: pointer;

        & > .cell {
            border: 0 solid transparent;
            border-width: 1px 0;
        }

        &:hover > .cell,
        &.open > .cell {
            border-color: var(--success, $success);
        }
    }
}

.cell {
    white-space: nowrap;

    &.sorted {
        color: var(--sorted-text-color, $sorted-text-color);
        background-color: var(--sorted-bg, $sorted-bg);
    }

    &.title {
        & > .g-flex {
            cursor: pointer;
        }

        &.sorted {
            font-weight: bold;
            fill: var(--sorted-text-color, $sorted-text-color);

            &.desc {
                .g-symbol {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &.id,
    &.leads,
    &.language,
    &.date {
        text-align: center;
    }

    &.geo {
        max-width: 196px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

@media (min-width: 1025px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                padding: 10px 8px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}

@media (max-width: 1024px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                padding: 8px 5px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}
</style>
