import { reactive, ref } from 'vue';
import { ApiClient, STACK} from '@/Ship';

import ValidRules from '@/Ship/core/ValidRules';

const validRules = new ValidRules();

export default class Settings {

    static #instance = null;
    endpoint = '/settings';
    referer = 'Setting Resource';

    model = reactive({});
    entity = {};

    loaded = ref([]);
    loading = ref([]);

    onload = [];

    constructor() {
        Settings.#instance = new Proxy(this.model, {
            get: (target, key) => {
                return key in this.model
                    ? this.model[key]
                    : this[key] || undefined;
            },
            set: (target, key, value) => {
                this.entity[key] = value;
                this.model[key] = value;
                return true;
            }
        });

        return Settings.#instance;
    }

    static getInstance() {
        return Settings.#instance
            ? Settings.#instance
            : new Settings;
    }

    get isLoaded() {
        return this.loaded.value;
    }

    get isLoading() {
        return this.loading.value;
    }

    load(section = null, params = {}) {
        //this.loading.value = true;
        let endpoint = this.endpoint;

        if (section) {
            this.loaded.value.splice(this.loaded.value.indexOf(section), 1);
            this.loading.value.push(section);
            endpoint = `${endpoint}/${section}`;
        } else if (params.sections) {
            params.sections.split(',').forEach(section => {
                const index = this.loaded.value.indexOf(section);
                if (index > -1) {
                    this.loaded.value.splice(index, 1);
                }
                this.loading.value.push(section);
            });
        }

        return STACK.push(() => ApiClient.get(endpoint, { params, headers: {Ref: this.referer} })).then(response => {
            Object.keys(response).forEach(section => {
                this.model[section] = response[section];
                this.loaded.value.push(section);
                const index = this.loading.value.indexOf(section);
                if (index > -1) {
                    this.loading.value.splice(index, 1);
                }
            });

            if (Array.isArray(this.onload)) {
                this.onload.forEach(task => {
                    task(response);
                });
            } else if (typeof this.onload === 'function') {
                this.onload(response);
            }
        });

    }

    save(section, fields = null) {
        return STACK.push(() => ApiClient.patch(
            `${this.endpoint}/${section}`,
            fields || this.entity[section],
            {headers: {Ref: this.referer}}
        ));
    }

    create(params) {
        return STACK.push(() => ApiClient.post(`${this.endpoint}`, params, {headers: {Ref: this.referer}}));
    }

    update(payload = null) {
        return STACK.push(() => {
            return ApiClient.patch(this.endpoint, payload, {headers: {Ref: this.referer}});
        });
    }

    delete(section, params) {
        return STACK.push(() => ApiClient.delete(`${this.endpoint}/${section}`, {data: params, headers: {Ref: this.referer}}));
    }

    clearEntity() {
        Object.keys(this.entity).forEach(key => delete this.entity[key]);
    }
}
