<script>
export default {
    name: 'GField',
    inheritAttrs: false,
}
</script>

<script setup>

import { ref, defineProps, defineEmits } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    modelValue: { type: String, default: ''},
    className: {type: String },
    disabled: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    label: { type: String },
    hint: { type: String },
    error: { type: String, default: '' },
    resetBtn: { type: Boolean, default: true },
    backlight: { type: Boolean, default: false }
});

const inp = ref();
const initValue = props.modelValue || '';

const inputEvent = event => {
    emit('update:modelValue', event.target.value);
};

const reset = () => {
    emit('update:modelValue', initValue);
};
</script>

<template>
    <div class="g-field" v-on:click="inp.focus()" v-bind:class="className">
        <fieldset class="field" v-bind:class="{ error, disabled, backlight, filled: !!modelValue }">
            <input ref="inp"
                   v-bind="$attrs"
                   v-bind:value="modelValue"
                   v-on:input="inputEvent"
                   v-bind:disabled="disabled"
                   v-bind:type="type"
                   v-bind:placeholder="label">

            <legend>{{ label }}</legend>

            <slot/>
            <g-symbol v-if="disabled" class="arrow" name="lock" width="20" height="20"/>
            <g-symbol v-else-if="resetBtn && modelValue !== initValue"
                      name="close"
                      width="20"
                      height="20"
                      v-on:click="reset"/>

            <div v-if="error || hint" class="hint-text">{{ error || hint }}</div>
        </fieldset>
    </div>
</template>

<style lang="scss" scoped>
.field {
    position: relative;
    padding: 0 10px 0 0;
    display: flex;
    white-space: nowrap;
    align-items: center;
    box-sizing: border-box;
    color: var(--main-text-color, $main-text-color);
    fill: var(--input-label-color, $label-color);
    border: 1px solid var(--input-border, $input-border);
    border-radius: var(--input-rounding, $input-rounding);
    transition: background-color 0.2s;

    legend {
        position: absolute;
        margin-left: 8px;
        padding: 0 2px;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        pointer-events: none;
        color: var(--label-color, $label-color);
        transition: font-size 0.3s;
        z-index: 1;
    }

    &.backlight {
        border-color: var(--secondary-hover, $secondary-hover);

        & > legend {
            color: var(--secondary-hover, $secondary-hover);
        }
    }

    input {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        border: none;
        padding-left: 10px;
        box-sizing: border-box;
        background-color: transparent;
        color: var(--title-color, $title-color);
        font-size: var(--input-font-size, $input-font-size);
        font-family: var(--font-primary, $font-primary);

        &::placeholder {
            color: transparent;
        }

        &:focus {
            outline: none;
        }

        &:not(:focus):placeholder-shown + legend {
            top: 50%;
            transform: translateY(-50%);
            font-size: var(--label-font-size, $label-font-size);
        }

        &:focus + legend,
        &:not(:placeholder-shown) + legend {
            font-size: 14px;
            height: 14px;
            line-height: 14px;
            position: relative;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            background: transparent;
            transition: background-color 5000s ease-in-out 0s;
            -webkit-text-fill-color: var(--main-text-color, $main-text-color);
        }
    }

    &:deep(.btn),
    &:deep(.g-symbol-currency),
    &:deep(.g-symbol) {
        cursor: pointer;
        flex-shrink: 0;
        fill: var(--main-text-color, $main-text-color);
        margin: 0 4px;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            fill: var(--title-color, $title-color);
        }
    }

    &:not(:focus-within),
    &:not(.filled) {
        margin-top: 7px;
        height: var(--input-height, $input-height);
        background-color: var(--input-bg, $input-bg);
    }

    &:focus-within,
    &.filled {
        margin-top: 0;
        height: calc(var(--input-height, $input-height) + 7px);
        background-color: var(--input-active-bg, $input-active-bg);
    }

    & > .hint-text {
        bottom: 90%;
        left: 50%;
        width: 100%;
        padding: 5px 15px;
        border-radius: 3px;
        position: absolute;
        transform: translateX(-50%);
        background-color: var(--hint-bg, $hint-bg);
        color: var(--hint-text-color, $hint-text-color);
        z-index: 1;

        &::after {
            content: '';
            bottom: -10px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            border: 5px solid transparent;
            border-top-color: var(--hint-bg, $hint-bg);
        }
    }

    &.error {
        border-color: var(--danger, $danger);

        & > legend {
            color: var(--danger, $danger);
        }

        .hint-text {
            white-space: normal;
            background-color: var(--danger, $danger);

            &::after {
                border-top-color: var(--danger, $danger);
            }
        }
    }

    &.disabled {
        opacity: 0.7;
        pointer-events: none;

        legend {
            color: var(--disabled-color, $disabled-color);
        }

        input {
            color: var(--disabled-color, $disabled-color);
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
}
</style>
