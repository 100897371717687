<script setup>

import { useSettingsActions, useSettingsComponents } from '@settings';
import { useProfileActions } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const {
    CrmSettings,
    TradingSettings,
	Statuses,
	AccountLevels,
    Brands
} = useSettingsComponents();

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource(['general', 'trading','statuses', 'account-levels']);
</script>

<template>
    <div class="settings-wrapper">
        <crm-settings v-if="settings.isLoaded.includes('general')"/>
        <trading-settings v-if="settings.isLoaded.includes('trading')"/>
        <brands/>
        <div class="gag"></div>
        <statuses v-if="settings.isLoaded.includes('statuses')"/>
        <account-levels v-if="settings.isLoaded.includes('account-levels')"/>
    </div>
</template>

<style scoped lang="scss">
.settings-wrapper {
    padding: 10px;
    display: grid;
    grid-gap: 10px;
}

.gag {
    grid-area: gag;

    & > .g-field {
        width: 180px;
    }
}

.statuses {
    grid-area: sts;
}

.account-levels {
    grid-area: acl;
}

.general {
    padding: 15px;
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);

    &.crm {
        grid-area: crm;
    }

    &.trading {
        grid-area: trd;
    }

    &.brands {
        grid-area: brd;
    }
}

@media (min-width: 1405px) {
    .settings-wrapper {
        grid-template-areas:
            'crm crm'
            'trd acl'
            'sts brd';
        grid-template-columns: 480px 600px;
    }
}

@media (max-width: 1404px) {
    .settings-wrapper {
        grid-template-areas:
            'crm'
            'trd'
            'sts'
            'acl'
            'brd';
        grid-template-columns: 1fr;
    }
}
</style>