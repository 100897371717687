<script setup>

import { reactive, ref, computed, defineEmits, watch } from 'vue';
import { i18n, PopUpManager as PopUp } from '@/Ship';
import { useProfileActions } from '@profile';
import { useLeadActions, useLeadResources } from '@lead';

const emit = defineEmits(['perpage']);

const getLeadResource = useLeadActions('getLeadResource');
const leads = getLeadResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const perPage = ref(profile.state.lead.perPage || 25);

const tab = ref('leads');
const tabList = reactive({
    leads: i18n.global.t(`parts.leads`),
    archive: i18n.global.t(`parts.archive`),
});

const filters = useLeadResources('FilterState');
Object.assign(filters.state, profile.state.lead.filters[profile.state.env.brand || 0]);
leads.setFilters(filters.state);

watch(() => profile.state.env.brand, () => {
//     Object.keys(filters.state).forEach(filter => {
//         delete filters.state[filter];
//     });
//
//     setTimeout(() => {
//         Object.assign(filters.state, profile.state.lead.filters[profile.state.env.brand || 0]);
//         leads.setFilters(filters.state);
//     }, 1000);
});

function getFilterValueByKey(filter, key) {
    if (leads.filterset[filter]) {
        key = String(key).split(',').pop();
        const filterset = leads.filterset[filter].find(item => item.id === Number(key) || item.value == key);

        let value;
        switch (filter) {
            case 'campaigns':
                value = profile.can('Lead RealCampaignView')
                    ? filterset?.name || key
                    : filterset?.alias || key;
                break;
            default:
                value = filterset
                    ? filterset.key || filterset.value || filterset.name
                    : key || 'Null';
                break;
        }

        return value;
    }

    return key;
}
function dropFilter(filter, value) {
    leads.dropFilterValue(filter, value);
    delete filters.state[filter];
    leads.setPage(1);
    leads.load(null, {}, 'Drop Filter');

    const brandId = profile.state.env.brand || 0;
    profile.state.lead.filters[brandId] = leads.filters;
    profile.saveState();
}

function resetAllFilters() {
    Object.keys(leads.filters).forEach(filter => {
        Object.keys(leads.filters[filter]).forEach(key => {
            leads.dropFilterValue(filter, key);
            delete filters.state[filter];
        })
    });

    const brandId = profile.state.env.brand || 0;
    profile.state.lead.filters[brandId] = {};

    leads.setFilters(leads.filters);
    leads.setPage(1);
    leads.load(null, {}, 'Reset Filters');

    profile.saveState();
}
</script>

<template>
    <div class="index-bar">
        <g-flex justify="between" align="end">

            <slot/>

            <div class="separator"></div>

            <g-flex class="toolbar" gap="15" align="center">
                <button v-if="profile.can('Lead Make')" class="tool with-tooltip" v-bind:data-tooltip="$t('lead.make_lead', 'Make Lead')">
                    <g-symbol name="plus"
                              width="24"
                              height="24"
                              v-on:click="PopUp.open(require('@lead/components/PopUps/MakeLead')).setCaption($t('lead.make_lead'), 'Make Lead')"/>
                </button>

                <button v-if="profile.can('Lead Make')" class="tool with-tooltip" v-bind:data-tooltip="$t('lead.leads_import', 'Leads Import')">
                    <g-symbol name="import"
                              width="24"
                              height="24"
                              v-on:click="PopUp.open(require('@lead/components/PopUps/Import.vue')).setCaption($t('lead.leads_import'), 'Leads Import')"/>
                </button>
                <button v-if="profile.can('Lead CheckDuplicates')" class="tool with-tooltip" v-bind:data-tooltip="$t('lead.check_base', 'Check Base')">
                    <g-symbol name="list"
                              width="20"
                              height="20"
                              v-on:click="PopUp.open(require('@lead/components/PopUps/CheckDuplicates.vue')).setCaption($t('lead.leads_check'), 'Check Leads')"/>
                </button>

                <button class="tool with-tooltip" v-bind:data-tooltip="$t('lead.columns_view', 'Columns View')">
                    <g-symbol name="settings"
                              width="24"
                              height="24"
                              v-on:click="PopUp.open(require('@lead/components/PopUps/ColumnViews')).setCaption($t('lead.columns_view'), 'Columns View')"/>
                </button>

                <g-select v-model="perPage"
                          class="per-page"
                          v-bind:label="$t('base.per_page')"
                          v-bind:options="[{value: 25}, {value: 50}, {value: 75}, {value: 100}]"
                          v-on:change="$emit('perpage', perPage)"
                          option-value="value"
                          option-text="value"/>
            </g-flex>
        </g-flex>

        <g-flex class="filters" align="center" gap="4" wrap="wrap">
            <div v-if="Object.keys(leads.filters).length > 0"
                 class="filter reset-all"
                 v-on:click="resetAllFilters">
                Reset All
                <div class="filter-item">
                    <g-symbol name="close" width="14" height="14"/>
                </div>
            </div>
            <div class="filter" v-for="(values, key) in leads.filters" v-bind:key="key" v-bind:class="key">
                {{ $t(`filters.${key}`, key) }}:
                <div class="filter-item" v-for="(value, i) in Object.keys(values)" v-bind:key="`${key}-${i}`">
                    <div class="value">{{ getFilterValueByKey(key, value) }}</div>
                    <g-symbol name="close" width="14" height="14" v-on:click="dropFilter(key, value)"/>
                </div>
            </div>
        </g-flex>

        <g-tabs v-if="profile.can('Lead ArchiveView')"
                v-model="tab"
                v-bind:tab-list="tabList"
                v-on:click="$router.push({ name: 'Archive', params: { locale: $i18n.locale } })"/>
    </div>
</template>

<style lang="scss" scoped>
.index-bar {
    top: 0;
    left: 0;
    z-index: 4;
    position: sticky;
    padding: 10px 15px 18px;
    border-radius: 0 0 5px 5px;
    background-color: var(--bar-bg, $bar-bg);
    border-bottom: 1px solid var(--separator-color, $separator-color);

    .per-page {
        width: 120px;
    }

    .separator {
        flex-grow: 1;
    }

    .tool {
        font-size: 0;
        margin-top: 8px;
        cursor: pointer;
        position: relative;
        background-color: transparent;
        border-radius: var(--btn-rounding, $btn-rounding);
        fill: var(--main-text-color, $main-text-color);

        &:hover {
            fill: var(--title-color, $title-color);

            &::after {
                white-space: nowrap;
                transform: translateX(-50%);
                top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
                left: 50%;
            }

            &::before {
                transform: translateX(-50%);
                left: 50%;
                top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
                border-bottom-color: var(--tooltip-bg, $tooltip-bg);
            }
        }
    }

    .top {
        container-name: top;

        .mass-update-btn {
            > span {
                display: none;
            }
        }
    }
}

.g-tabs {
    margin-top: 8px;
    max-width: max-content;
}

.filters {
    min-height: 46px;
}

.filter {
    padding-left: 8px;
    border-radius: 5px;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    color: white;
    border: 1px solid;
    box-shadow: 0 5px 15px -10px #000000aa;
    flex-flow: wrap;

    & > .filter-item {
        padding: 2px 4px 2px 8px;
        display: inline-flex;
        align-items: center;
        gap: 2px;
        height: 28px;

        &:first-child {
            margin-left: 5px;
        }

        & > .value {
            font-weight: 500;
        }

        & > .g-symbol {
            cursor: pointer;
            fill: var(--btn-primary-color, $btn-primary-color);
        }
    }

    &.reset-all {
        background-color: black;
        border-color: #333333;

        & > .filter-item {
            background-color: #333333;
        }
    }

    &.is_ftd {
        background-color: #d08300;
        border-color: #eaa308;

        & > .filter-item {
            background-color: #eaa308;
        }
    }

    &.created_at {
        background-color: var(--primary, $primary);
        border-color: var(--primary-hover, $primary-hover);

        & > .filter-item {
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    &.ftd_date {
        background-color: var(--secondary, $secondary);
        border-color: var(--secondary-hover, $secondary-hover);

        & > .filter-item {
            background-color: var(--secondary-hover, $secondary-hover);
        }
    }

    &.conversion_status {
        background-color: #008060;
        border-color: #22a585;

        & > .filter-item {
            background-color: #22a585;
        }
    }

    &.retention_status {
        background-color: #700099;
        border-color: #b010df;

        & > .filter-item {
            background-color: #b010df;
        }
    }

    &:not(.is_ftd, .created_at, .ftd_date, .conversion_status, .retention_status, .reset-all) {
        background-color: var(--danger, $danger);
        border-color: var(--danger-hover, $danger-hover);

        & > .filter-item {
            background-color: var(--danger-hover, $danger-hover);

            & > .value {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 80px;
            }
        }
    }
}
</style>
