<script setup>
import { defineEmits, computed } from 'vue';
import { useLeadActions } from '@lead';
import { useSettingsActions } from "@settings";

const emit = defineEmits(['performed','close']);

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource('trading');

const cryptoFee = computed({
    get: () => lead.model.specifics?.crypto_fee || settings.trading[lead.model.brand]?.defaultCryptoFee || 0.001,
    set: value => {
        const specifics = lead.model.specifics;
        specifics.crypto_fee = value;
        lead.model.specifics = specifics;

        return true;
    }
});

const stockFee = computed({
    get: () => lead.model.specifics?.stock_fee || settings.trading[lead.model.brand]?.defaultStockFee || 0.001,
    set: value => {
        const specifics = lead.model.specifics;
        specifics.stock_fee = value;
        lead.model.specifics = specifics;

        return true;
    }
});

const forexFee = computed({
    get: () => lead.model.specifics?.forex_fee || settings.trading[lead.model.brand]?.defaultForexFee || 0.001,
    set: value => {
        const specifics = lead.model.specifics;
        specifics.forex_fee = value;
        lead.model.specifics = specifics;

        return true;
    }
});

const minDealAmountCrypto = computed({
    get: () => lead.model.specifics?.min_amount_crypto || settings.trading[lead.model.brand]?.defaultMinDealAmountCrypto || 100,
    set: value => {
        const specifics = lead.model.specifics;
        specifics.min_amount_crypto = value;
        lead.model.specifics = specifics;

        return true;
    }
});
const minDealAmountForex = computed({
    get: () => lead.model.specifics?.min_amount_forex || settings.general[lead.model.brand]?.defaultMinDealAmountForex || 100,
    set: value => {
        const specifics = lead.model.specifics;
        specifics.min_amount_forex = value;
        lead.model.specifics = specifics;

        return true;
    }
});
const minDealAmountStock = computed({
    get: () => lead.model.specifics?.min_amount_stock || settings.trading[lead.model.brand]?.defaultMinDealAmountStock || 100,
    set: value => {
        const specifics = lead.model.specifics;
        specifics.min_amount_stock = value;
        lead.model.specifics = specifics;

        return true;
    }
});

const can_open_position = computed({
    get: () => lead.model.specifics.can_open_position === undefined ? true : lead.model.specifics.can_open_position,
    set: value => {
        const specifics = lead.model.specifics;
        specifics.can_open_position = value;
        lead.model.specifics = specifics;

        return true;
    }
});

const can_close_position = computed({
    get: () => lead.model.specifics.can_close_position === undefined ? true : lead.model.specifics.can_close_position,
    set: value => {
        const specifics = lead.model.specifics;
        specifics.can_close_position = value;
        lead.model.specifics = specifics;

        return true;
    }
});

function save() {
    lead.save().then(() => {
        emit('close');
    });
}

</script>

<template>
    <form v-on:submit.prevent="save">

        <!--<g-checkbox v-model:checked="canTrade">{{ $t('trading.can_trade', 'Can trade') }}</g-checkbox>-->
        <fieldset>
            <legend>{{ $t('trading.can_trade', 'Can trade') }}</legend>
            <g-flex class="can-trade" align="center" justify="end" gap="8">
                <label>Can open positions:</label>

                <g-switch v-model:checked="can_open_position"/>

                <span class="state" v-bind:class="can_open_position ? 'on' : 'off'">
                        {{ can_open_position ? $t('base.yes') : $t('base.no') }}
                    </span>
            </g-flex>

            <g-flex class="can-trade" align="center" justify="end" gap="8">
                <label>Can close positions:</label>

                <g-switch v-model:checked="can_close_position"/>

                <span class="state" v-bind:class="can_close_position ? 'on' : 'off'">
                        {{ can_close_position ? $t('base.yes') : $t('base.no') }}
                    </span>
            </g-flex>
        </fieldset>

        <div class="controls">
            <fieldset>
                <legend>Crypto</legend>

                <g-flex align="center" gap="8">
                    <g-field v-model="cryptoFee" v-bind:label="$t('trading.fee', 'Fee')"/>
                    <g-field v-model="minDealAmountCrypto" v-bind:label="$t('trading.min_deal_amount', 'Min deal amount')"/>
                </g-flex>
            </fieldset>

            <fieldset>
                <legend>{{ $t('trading.stocks', 'Stocks') }}</legend>

                <g-flex align="center" gap="8">
                    <g-field v-model="stockFee" v-bind:label="$t('trading.fee', 'Fee')"/>
                    <g-field v-model="minDealAmountStock" v-bind:label="$t('trading.min_deal_amount', 'Min deal amount')"/>
                </g-flex>
            </fieldset>

            <fieldset>
                <legend>Forex</legend>

                <g-flex align="center" gap="8">
                    <g-field v-model="forexFee" v-bind:label="$t('trading.fee', 'Fee')"/>
                    <g-field v-model="minDealAmountForex" v-bind:label="$t('trading.min_deal_amount', 'Min deal amount')"/>
                </g-flex>
            </fieldset>
        </div>

        <g-flex class="btn-bar" justify="end" gap="5">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button class="save-btn" type="submit">{{ $t('base.save', 'Save') }}</g-button>
        </g-flex>
    </form>
</template>

<style scoped lang="scss">
form {
    max-width: 450px;
}

fieldset {
    margin-bottom: 10px;
    padding: 5px 12px 12px;
    border: 1px solid var(--separator-color, $separator-color);
}

.btn-bar {
    padding-top: 30px;

    & > .g-button {
        padding: 0 20px;
        min-width: 100px;
    }
}

.g-field {
    width: 180px;
}

.can-trade {
    margin-top: 10px;

    & > .state {
        width: 30px;

        &.on {
            color: var(--success-hover, $success-hover);
        }

        &.off {
            color: var(--disabled-color, $disabled-color);
        }
    }
}
</style>