<script setup>
import { useProfileActions } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

</script>

<template>
    <nav>
        <router-link v-if="profile.can('Settings GeneralEdit')"
                     class="link"
                     v-bind:class="{active: $route.name === 'Settings'}"
                     v-bind:to="{ name: 'Settings', params: $route.params }">
            {{ $t('base.general', 'General') }}
        </router-link>
        <router-link v-if="profile.can('Permission View')"
                     class="link"
                     v-bind:class="{active: $route.name === 'Permissions'}"
                     v-bind:to="{ name: 'Permissions', params: $route.params }">
            {{ $t('base.permissions', 'Permissions') }}
        </router-link>
        <router-link v-if="profile.can('Settings CountriesEdit')"
                     class="link"
                     v-bind:class="{active: $route.name === 'Countries'}"
                     v-bind:to="{ name: 'Countries', params: $route.params }">
            {{ $t('base.countries', 'Countries') }}
        </router-link>
        <router-link v-if="profile.can('Team View')"
                     class="link"
                     v-bind:class="{active: $route.name === 'Teams'}"
                     v-bind:to="{ name: 'Teams', params: $route.params }">
            {{ $t('parts.teams', 'Teams') }}
        </router-link>
        <router-link v-if="profile.can('Asset View')"
                     class="link"
                     v-bind:class="{active: $route.name === 'Assets'}"
                     v-bind:to="{ name: 'Assets', params: $route.params }">
            {{ $t('parts.assets', 'Assets') }}
        </router-link>
    </nav>
</template>

<style lang="scss" scoped>
nav {
    padding: 20px 10px;
    height: 100%;
    gap: 6px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: end;
}

.link {
    display: block;
    font-weight: 500;
    font-size: 1.1rem;
    color: var(--sidebar-text-color, $sidebar-text-color);

    &:hover {
        color: var(--primary-hover, $primary-hover);
    }

    &.active {
        pointer-events: none;
        color: var(--success, $success);
    }
}
</style>