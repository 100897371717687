<script setup>

import { computed, onMounted } from 'vue';
import { i18n, PopUpManager as PopUp } from '@/Ship';
import { useTeamActions } from '@team';
import { preloaderAligner } from '@/Ship/components/global/preloaders/scrollHellper';
import { useProfileActions } from '@profile';

const getTeamResource = useTeamActions('getTeamResource');
const teams = getTeamResource(true);

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const desks = computed(() => {
    return teams.state.map(team => team.desk).filter(item => item).filter((value, index, array) => {
        return array.indexOf(value) === index;
    });
});

onMounted(() => {
    preloaderAligner('.environment');
});

function removeTeam(index) {
    PopUp
        .setCaption(i18n.global.t('teams.remove_team', 'Remove team'))
        .setMessage(`<p class="message"><b>${i18n.global.t('teams.remove_confirm', { id: teams.state[index].id }, 'The team with ID:{id} will be permanently deleted')}!</b></p>`)
        .setAction(() => {
            teams.delete(teams.state[index].id).then(() => {
                teams.state.splice(index, 1);
            });

            PopUp.close();
        })
        .open(require('@/Ship/components/PopUps/Confirm'));
}

function updateTeam(index, field) {
    teams.save(teams.state[index].id, {
        [field]: teams.state[index][field]
    });
}
</script>

<template>
    <div class="team-list">
        <div class="index-bar">
            <g-flex justify="between" align="end">
                <slot/>
                <g-flex class="toolbar" gap="5" align="center">
                    <g-button class="tool"
                              v-on:click="PopUp.open(require('@team/components/PopUps/MakeTeam')).setCaption($t('settings.make_team', 'Make Team'))"
                              v-bind:disabled="profile.cant('Team Make')">
                        <g-symbol name="plus" width="14" height="14"/>
                        {{ $t('settings.make_team', 'Make Team') }}
                    </g-button>
                </g-flex>
            </g-flex>
            <indexbar/>
        </div>
        <div class="index">
            <div class="row header">
                <div class="id title cell">ID</div>
                <div class="name title cell">{{ $t('settings.team_name', 'Team name') }}</div>
                <div class="desk title cell"></div>
                <div class="desk title cell"></div>
                <div class="desk title cell">{{ $t('settings.team_members', 'Members') }}</div>
                <div class="date title cell">{{ $t('base.created_at', 'Created at') }}</div>
                <div class="action title cell"></div>
            </div>
            <g-preloader-content :is-loading="teams.isLoading"/>
            <div class="row" v-for="(team, i) in teams.state" :key="`team-${team.id}`">
                <div class="id cell">{{ team.id }}</div>
                <div class="name cell">{{ team.name }}</div>
                <div class="desk cell">
                    <g-flex align="end" gap="3">
                        <g-combobox v-model="teams.state[i].desk"
                                    v-bind:label="$t('teams.desk', 'Desk')"
                                    v-bind:options="desks"
                                    v-bind:disabled="profile.cant('Team Edit')"/>
                        <g-button class="action-btn save-desk-btn with-tooltip"
                                  v-on:click="updateTeam(i, 'desk')"
                                  v-bind:data-tooltip="$t('teams.save_desk', 'Save desk')"
                                  v-bind:disabled="profile.cant('Team Edit')">
                            <g-symbol name="send" width="16" height="16"/>
                        </g-button>
                    </g-flex>
                </div>
                <div class="teamlead cell">
                    <g-select v-model="teams.state[i].teamlead_id"
                              v-bind:options="(team.users || []).filter(user => user.roles.map(role => role.name).includes('Teamlead'))"
                              option-text="name"
                              option-value="id"
                              v-on:change="updateTeam(i, 'teamlead_id')"
                              v-bind:label="$t('teams.team_lead', 'Team lead')"
                              v-bind:disabled="profile.cant('Team Edit')"/>
                </div>
                <div class="members cell">{{ (team.users || []).map(user => user.name).join(', ') }}</div>
                <div class="date cell">{{ team.created_at }}</div>
                <div class="action cell">
                    <g-button class="action-btn remove-btn with-tooltip"
                              type="button"
                              v-bind:data-tooltip="$t('teams.remove_team', 'Remove')"
                              v-on:click="removeTeam(i)"
                              v-bind:disabled="profile.cant('Team Remove')">
                        <g-symbol name="delete" width="16" height="16"/>
                    </g-button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.environment {
    background-color: var(--env-bg, $env-bg);
}

.index-bar {
    top: 0;
    left: 0;
    z-index: 2;
    position: sticky;
    padding: 10px 15px 18px;
    border-radius: 0 0 5px 5px;
    background-color: var(--bar-bg, $bar-bg);
    border-bottom: 1px solid var(--separator-color, $separator-color);

    .tool {
        margin-top: 8px;
        cursor: pointer;
        position: relative;
        padding: 0 10px;
        background-color: transparent;
        fill: var(--main-text-color, $main-text-color);
        color: var(--main-text-color, $main-text-color);
        border: 1px solid var(--main-text-color, $main-text-color);

        &:hover {
            color: var(--btn-primary-color, $btn-primary-color);
            fill: var(--btn-primary-color, $btn-primary-color);
            background-color: var(--success, $success);
            border-color: var(--success, $success);
        }
    }
}

.action-btn {
    flex-shrink: 0;
    width: 38px;
    font-size: 0;
    position: relative;
    background-color: transparent;
    color: var(--main-text-color, $main-text-color);
    fill: var(--main-text-color, $main-text-color);
    border: 1px solid var(--separator-color, $separator-color);

    &::after {
        left: -30%;
        transform: translateX(-50%);
        top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &::before {
        transform: translateX(-50%);
        left: 50%;
        top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &:hover {
        fill: var(--title-color, $title-color);
    }
}

.save-desk-btn {
    &:hover {
        border-color: var(--primary, $primary);
        background-color: var(--primary, $primary);
        fill: var(--btn-primary-color, $btn-primary-color);

        &::after {
            background-color: var(--primary, $primary);
        }

        &::before {
            border-bottom-color: var(--primary, $primary);
        }
    }
}

.remove-btn {
    &:hover {
        border-color: var(--danger, $danger);
        background-color: var(--danger, $danger);
        fill: var(--btn-primary-color, $btn-primary-color);

        &::after {
            background-color: var(--danger, $danger);
        }

        &::before {
            border-bottom-color: var(--danger, $danger);
        }
    }
}

.send-btn {
    width: 35px;
    height: 35px;
    color: white;
    margin: 0 2px;
    font-size: 0;
    background-color: transparent;
    fill: var(--main-text-color, $main-text-color);
    border: 1px solid var(--main-text-color, $main-text-color);
    position: relative;

    &::after {
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &::before {
        transform: translateX(-50%);
        left: 50%;
        top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &:hover {
        fill: var(--title-color, $title-color);
        border-color: var(--success-hover, $success-hover);
        background-color: var(--success-hover, $success-hover);

        &::after {
            background-color: var(--success-hover, $success-hover);
        }

        &::before {
            border-bottom-color: var(--success-hover, $success-hover);
        }
    }
}

.index {
    min-width: 100%;
    padding: 0 10px;
    position: relative;
    min-height: 100px;
}

.row {
    fill: var(--main-text-color, $main-text-color);

    &:nth-child(odd) {
        background-color: var(--env-bg, $env-bg);
    }

    &:nth-child(even) {
        background-color: var(--bar-bg, $bar-bg);
    }

    &:not(.header) {
        cursor: pointer;

        & > .cell {
            border: 0 solid transparent;
            border-width: 1px 0;
        }
    }
}

.cell {
    white-space: nowrap;

    &.title {
        cursor: default;
    }

    &.members {
        white-space: normal;
    }

    &.date {
        text-align: center;
    }

    &.action {
        width: 58px;
    }
}

@media (min-width: 1025px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                padding: 8px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}

@media (max-width: 1024px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                padding: 8px 5px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}
</style>
