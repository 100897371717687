<script setup>
import { defineProps, defineEmits, reactive, ref, watch, computed } from 'vue';
import { useShipActions, verifyField } from '@/Ship';
import { useTradingActions } from '@trading';
import { useLeadActions } from '@lead';
import { useFinanceActions } from '@finance';
import { useSettingsActions } from '@settings';

const props = defineProps(['payload']);
const emit = defineEmits(['performed', 'close']);

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource('trading');

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

lead.index = lead.state.findIndex(lead => lead.customer_id === props.payload.customer_id);

const getRates = useFinanceActions('getRates');

const {
    plCalculate,
    getPositionResource
} = useTradingActions();
const resource = getPositionResource();

const onlyNumberInput = useShipActions('onlyNumberInput');

const isLock = ref(false);
const errors = reactive({
    amount: null,
    value: null,
    entryPrice: null,
    takeProfit: null,
    stopLoss: null,
    swap: null,
    created_at: null
});

watch(errors, () => {
    isLock.value = false;
    for (const key in errors) {
        if (errors[key] !== null) {
            isLock.value = true;
            break;
        }
    }
});

const pair = `${props.payload.pair.base}:${props.payload.pair.counter}`;

const targetPayload = reactive({});
const position = new Proxy(targetPayload, {
    get(target, key) {
        return target[key] || props.payload[key];
    },
    set(target, key, value) {
        target[key] = value;

        return true;
    }
});

const prices = reactive({});
const symbols = [position.pair_symbol];
const marketCurrency = (position.pair.market === 'forex' || position.pair.market === 'commodities') ? 'USD' : 'USDT';

if (position.pair.counter !== marketCurrency && position.pair.base !== marketCurrency) {
    symbols.push(`${position.pair.counter}${marketCurrency}`);
    symbols.push(`${marketCurrency}${position.pair.counter}`);
}

loadPrices(symbols);

function loadPrices(symbols) {
    getRates(symbols).then(({ data }) => {
        data.forEach(quote => {
            const multiplier = (parseInt(lead.model.specifics.multipliers[quote.symbol]) || 0) / 100;
            prices[quote.symbol] = Number(quote.price) + (quote.price * multiplier);
        });
    });
}

const fee = computed(() => {
    return {
        crypto: lead.model.specifics.crypto_fee || settings.trading[lead.model.brand].defaultCryptoFee || 0.001,
        stocks: lead.model.specifics.stock_fee || settings.trading[lead.model.brand].defaultStockFee || 0.001,
        forex: lead.model.specifics.forex_fee || settings.trading[lead.model.brand].defaultForexFee || 0.001,
        commodities: lead.model.specifics.forex_fee || settings.trading[lead.model.brand].defaultForexFee || 0.001,
    }[position.pair.market];
});

const pl = computed(() => {
    return plCalculate(position, fee.value, prices);
});

const marketPrice = computed(() => {
    return Number((prices[position.pair_symbol] || 0).toFixed(5));
});

const entryPrice = computed({
    get() {
        return position.entry_price;
    },
    set(value) {
        position.entry_price = value;
        position.value = position.total_amount / value;

        errors.entryPrice = value > 0
            ? null
            : 'Entry Price must be greater than zero';

        return true;
    }
});

const amount = computed({
    get() {
        return position.amount;
    },
    set(value) {
        const usdtAmount = ['USDT', 'USD'].includes(props.payload.pair.counter)
            ? value
            : value * position.entry_price; // props.payload.entry_price;

        position.amount = value;

        if (value > 0) {
            if ((usdtAmount - props.payload.usdt_amount) <= lead.model.balance) {
                errors.amount = null;

                position.usdt_amount = usdtAmount;
                position.total_amount = value * position.leverage;
                position.value = Number((position.total_amount / position.entry_price).toFixed(5));
            } else {
                errors.amount = 'Not enough Balance!';
            }
        } else {
            errors.amount = 'Amount must be greater than zero';
        }

        return true;
    }
});

const leverage = computed({
    get() {
        return position.leverage;
    },
    set(value) {
        position.leverage = value;
        position.total_amount = position.amount * value;
        position.value = Number((position.total_amount / position.entry_price).toFixed(5));

        return true;
    }
});

const value = computed({
    get() {
        return position.value;
    },
    set(value) {
        position.value = Number(value);
        const price = (position.amount * position.leverage) / value;
        position.entry_price = ['USDT', 'USD'].includes(String(props.payload.pair.counter))
            ? position.entry_price = Number(price.toFixed(2))
            : position.entry_price = Number(price.toFixed(5));

        errors.value = value > 0
            ? null
            : 'Value must be greater than zero';

        return true;
    }
});

const direction = computed({
    get() {
        return position.direction;
    },
    set(value) {
        position.direction = value;
        const tmp = position.take_profit;
        position.take_profit = position.stop_loss;
        position.stop_loss = tmp;

        return true;
    }
});

const takeProfit = computed({
    get() {
        return position.take_profit;
    },
    set(value) {
        position.take_profit = value;

        if (value > 0) {
            const factor = position.direction === 'Long' ? 1 : -1;

            errors.takeProfit = ((position.entry_price - value) * factor < 0)
                ? null
                : `Take Profit mast be ${(factor > 0 ? 'greater' : 'less')} than the Entry Price!`;
        } else {
            errors.takeProfit = 'Take Profit must be greater than zero';
        }

        return true;
    }
});

const stopLoss = computed({
    get() {
        return position.stop_loss;
    },
    set(value) {
        position.stop_loss = value;

        if (value > 0) {
            const factor = position.direction === 'Long' ? -1 : 1;

            errors.stopLoss = ((position.entry_price - value) * factor < 0)
                ? null
                : errors.stopLoss = `Stop Loss must be ${(factor > 0 ? 'greater' : 'less')} than the Entry Price!`;

        } else {
            errors.stopLoss = 'Stop Loss must be greater than zero';
        }

        return true;
    }
});

const swap = computed({
    get: () => position.swap,
    set: value => {
        position.swap = value;
        errors.swap = verifyField(value, ['require']);
        return true;
    }
});

const openedAt = computed({
    get() {
        return position.created_at;
    },
    set(value) {
        position.created_at = value;
        const d = Date.parse(value.replace(/\./g, '-'));
        position.swap = (((Date.now() - d) / 86400000) >> 0) * fee.value;
        errors.created_at = verifyField(value, ['require']);
        return true;
    }
});

function savePosition() {
    resource.save(props.payload.id, targetPayload).then(() => {
        Object.assign(resource.state[resource.index], position);
        emit('close');
    });
}
</script>

<template>
    <form v-on:submit.prevent="savePosition">
        <g-field v-model="position.id" label="ID" readonly>
            <g-symbol name="lock" width="18" height="18"/>
        </g-field>
        <g-field v-model="pair" v-bind:label="payload.pair.market.toUpperCase()" readonly>
            <g-symbol name="lock" width="18" height="18"/>
        </g-field>
        <g-select v-model="direction"
                  v-bind:label="$t('trading.direction', 'Direction')"
                  v-bind:options="[{value: 'Long'},{value: 'Short'}]"
                  option-text="value"
                  option-value="value"/>

        <g-field v-model="amount"
                 v-bind:error="errors.amount"
                 v-on:keypress="onlyNumberInput"
                 v-bind:label="$t('trading.amount', 'Amount') + ', ' + payload.pair.counter"/>

        <g-field v-model="entryPrice"
                 v-bind:error="errors.entryPrice"
                 v-on:keypress="onlyNumberInput"
                 v-bind:label="$t('trading.entry_price', 'Entry Price') + ', ' + payload.pair.counter"/>
        <!--<g-field v-model="position.usdt_amount" v-bind:label="$t('trading.usdt_amount', 'USDT Amount')"/>-->
        <g-select v-model="leverage"
                  v-bind:label="$t('trading.leverage', 'Leverage')"
                  v-bind:options="[{n: '✕1', v: 1},{n: '✕2', v: 2}, {n: '✕3', v: 3}, {n: '✕5', v: 5}, {n: '✕10', v: 10}, {n: '✕15', v: 15}, {n: '✕20', v: 20}]"
                  option-text="n"
                  option-value="v"/>
        <g-field v-model="position.total_amount"
                 v-bind:label="$t('trading.total', 'Total') + ', ' + payload.pair.counter" readonly>
            <g-symbol name="lock" width="18" height="18"/>
        </g-field>
        <g-field v-model="value"
                 v-bind:error="errors.value"
                 v-on:keypress="onlyNumberInput"
                 v-bind:label="$t('trading.value', 'Value') + ', ' + payload.pair.base"/>

        <g-field v-model="pl" v-bind:label="$t('trading.pl', 'Profit & Loss')" readonly>
            <g-symbol name="lock" width="18" height="18"/>
        </g-field>
        <g-field v-model="marketPrice" v-bind:label="$t('trading.market_price', 'Market price')" v-bind:resetBtn="false"
                 readonly>
            <g-symbol name="lock" width="18" height="18"/>
            <g-symbol name="refresh" width="18" height="18" v-on:click="loadPrices(symbols)"/>
        </g-field>

        <g-field v-model="takeProfit"
                 v-bind:error="errors.takeProfit"
                 v-on:keypress="onlyNumberInput"
                 v-bind:label="$t('trading.take_profit', 'Take Profit') + ', ' + payload.pair.counter"/>
        <g-field v-model="stopLoss"
                 v-bind:error="errors.stopLoss"
                 v-on:keypress="onlyNumberInput"
                 v-bind:label="$t('trading.stop_loss', 'Stop Loss') + ', ' + payload.pair.counter"/>
        <g-field v-model="swap"
                 v-on:keypress="onlyNumberInput"
                 :error="errors.swap"
                 v-bind:label="$t('trading.swap', 'Swap')"/>
        <g-field v-model="openedAt" :error="errors.created_at" v-bind:label="$t('base.created_at', 'Created at')"/>
        <div></div>
        <g-flex class="btn-bar" gap="5">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button class="save-btn" type="submit" v-bind:disabled="isLock">{{ $t('base.save', 'Save') }}</g-button>
        </g-flex>
    </form>
</template>

<style scoped lang="scss">
form {
    max-width: 480px;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
}

.g-button {
    flex-grow: 1;
}
</style>