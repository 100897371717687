<template>
    <div>
        <div class="viewbar">
            <span class="close-btn" v-on:click="closeLead">
                <g-symbol name="close" width="24" height="24"/>
            </span>
        </div>
        <div class="card">
            <g-preloader-content v-bind:is-loading="leads.isLoading"/>
            <g-flex align="center" justify="between" class="lead-bar">
                <g-caption size="4">
                    <span v-if="leads.model.is_test === 'Yes'" class="test">{{
                            $t('lead.test_lead', 'Test Lead')
                        }}</span>
                    <span class="lead-id" :class="{'balance': leads.model.is_test === 'Yes'}">ID: {{
                            leads.model.id
                        }}</span>
                    <span v-if="profile.can('Lead BalanceView')" class="balance">
                        {{ $t('lead.balance') }}:
                        <g-symbol name="tether" fill="grey" width="20" height="20"/>
                        {{ leads.model.balance }}
                    </span>
                </g-caption>
                <div>
                    <g-button type="button"
                              class="online-indicator with-tooltip"
                              v-bind:class="{active: !leads.model.customer.online_at}"
                              v-bind:data-tooltip="!leads.model.customer.online_at ? $t('lead.lead_online') : $t('lead.lead_offline')">
                        <g-symbol name="online" width="20" height="20"/>
                    </g-button>
                    <g-button type="button"
                              class="blocked-indicator with-tooltip"
                              v-bind:class="{active: leads.model.customer.is_locked === 'Yes'}"
                              v-bind:data-tooltip="leads.model.customer.is_locked === 'Yes' ? $t('lead.client_is_blocked') : $t('lead.client_active')">
                        <g-symbol name="lock" width="20" height="20"/>
                    </g-button>
                    <g-button v-if="leads.model.customer_id && profile.can('Lead Impersonate')"
                              class="impersonate-btn with-tooltip"
                              type="button"
                              v-on:click="impersonate(leads.model.customer_id)"
                              v-bind:data-tooltip="$t('lead.impersonate', 'Impersonate')">
                        <g-symbol name="incognito" width="20" height="20"/>
                    </g-button>
                    <!--
                    <g-button v-else
                              type="button"
                              class="activate-btn with-tooltip"
                              v-bind:data-tooltip="$t('base.activate')"
                              v-on:click="PopUp.open(require('@lead/components/PopUps/LeadActivate')).setCaption('Activate the Customer')">
                        <g-symbol name="rocket" width="18" height="18"/>
                    </g-button>
                    -->

                    <g-button v-if="profile.can('Lead Archive')"
                              class="archive-btn with-tooltip"
                              type="button"
                              v-on:click="leadArchiving"
                              v-bind:data-tooltip="$t('base.archive')">
                        <g-symbol name="archive" width="18" height="18"/>
                    </g-button>

                    <g-button v-if="profile.can('Lead Remove')"
                              class="remove-btn with-tooltip"
                              type="button"
                              v-on:click="removeLead"
                              v-bind:data-tooltip="$t('base.remove')">
                        <g-symbol name="delete" width="18" height="18"/>
                    </g-button>
                </div>
            </g-flex>
            <aside>
                <g-flex class="tab-bar" direction="column" justify="center" align="center">
                    <button 
                            class="tab-btn new-tab with-tooltip"
                            v-bind:data-tooltip="$t('base.open_in_new_tab', 'Open in new tab')"
                            v-on:click="openInNewTab">
                        <g-symbol name="external_link" width="22" height="22"/>
                    </button>
                    <button class="tab-btn with-tooltip"
                            v-bind:class="{active: tab === 'Profile'}"
                            v-bind:data-tooltip="$t('lead.profile', 'Profile')"
                            v-on:click="openTab('Profile')">
                        <g-symbol name="profile" width="22" height="22"/>
                    </button>
                    <button v-if="profile.can('Comment View')" class="tab-btn with-tooltip"
                            v-bind:class="{active: tab === 'Comments'}"
                            v-bind:data-tooltip="$t('lead.comments', 'Comments')"
                            v-on:click="openTab('Comments')">
                        <g-symbol name="comments" width="22" height="22"/>
                    </button>
                    <button v-if="profile.can('Timeline View')" class="tab-btn with-tooltip"
                            v-bind:class="{active: tab === 'Timeline'}"
                            v-bind:data-tooltip="$t('lead.timeline', 'Timeline')"
                            v-on:click="openTab('Timeline')">
                        <g-symbol name="timeline" width="22" height="22"/>
                    </button>
                    <button v-if="profile.can('Lead ActivityView')"
                            class="tab-btn with-tooltip"
                            v-bind:class="{active: tab === 'Activity'}"
                            v-bind:data-tooltip="$t('lead.customer_activity', 'Customer activity')"
                            v-on:click="openTab('Activity')">
                        <g-symbol name="activity" width="22" height="22"/>
                    </button>
                    <button v-if="profile.can('Deposit View') || profile.can('Withdraw View')"
                            class="tab-btn with-tooltip"
                            v-bind:class="{active: tab === 'Finances'}"
                            v-bind:data-tooltip="$t('lead.finances', 'Finances')"
                            v-on:click="openTab('Finances')">
                        <g-symbol name="tether" width="22" height="22"/>
                    </button>
                    <button v-if="profile.can('Position View')"
                            class="tab-btn with-tooltip"
                            v-bind:class="{active: tab === 'Trading'}"
                            v-bind:data-tooltip="$t('lead.trading', 'Trading')"
                            v-on:click="openTab('Trading')">
                        <g-symbol name="chart" width="22" height="22"/>
                    </button>
                    <button v-if="profile.can('Lead KycStatusView')"
                            class="tab-btn with-tooltip"
                            v-bind:class="{active: tab === 'Kyc'}"
                            v-bind:data-tooltip="$t('lead.kyc', 'KYC')"
                            v-on:click="openTab('Kyc')">
                        <g-symbol name="docs" width="22" height="22"/>
                    </button>
                </g-flex>
                <div class="lead custom-scrollbar" v-if="Number.isInteger(leads.index) && leads.index > -1">
                    <component v-if="settings.isLoaded.includes('trading')" v-bind:is="tab" v-bind:key="leads.index"/>
                </div>
            </aside>
        </div>
    </div>
</template>

<script>

import { ref, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { STATE, i18n, PopUpManager as PopUp } from '@/Ship';
import { useLeadActions, useLeadComponents } from '@lead';
import { useProfileActions } from '@profile';
import Comments from '@comment';
import Timeline from '@timeline';
import Activity from '@activity';
import Trading from '@trading';
import { useSettingsActions } from '@settings';

const {
    archiving,
    impersonate,
    getLeadResource,
} = useLeadActions();

const getProfileResource = useProfileActions('getProfileResource');

const getSettingsResource = useSettingsActions('getSettingsResource');

const {
    Profile,
    Finances,
    Kyc
} = useLeadComponents();

export default {
    name: 'Lead',
    components: {
        Comments,
        Timeline,
        Activity,
        Trading,
        Profile,
        Finances,
        Kyc
    },

    setup() {
        const settings = getSettingsResource(['account-levels','statuses','trading']);
        const leads = getLeadResource();
        const profile = getProfileResource();

        const router = useRouter();
        const route = useRoute();
        const tab = ref(profile.state.lead.tab || 'Profile');

        onUnmounted(() => {
            leads.index = null;
        });

        function openTab(tabName) {
            tab.value = tabName;
            profile.state.lead.tab = tabName;
            profile.saveState();
        }

        function leadArchiving() {
            PopUp
            .setCaption(i18n.global.t('lead.remove_lead', 'Remove Lead'))
            .setMessage(`<p class="message"><b>${i18n.global.t('lead.archive_confirm', { id: leads.model.id }, 'The Customer with ID:{id} will be archived')}!</b></p></p>`)
            .setAction(() => {
                archiving.archive(leads.model.id).then(() => {
                    leads.state.splice(leads.index, 1);
                    leads.index = null;
                    router.push({ name: 'Leads', params: route.params });
                });

                PopUp.close();
            })
            .open(require('@/Ship/components/PopUps/Confirm'));
        }

        function removeLead() {
            PopUp
            .setCaption(i18n.global.t('lead.remove_lead', 'Remove Customer'))
            .setMessage(`<p class="message"><b>${i18n.global.t('lead.remove_confirm', { id: leads.model.id }, 'The Customer with ID:{id} will be permanently deleted')}!</b></p>`)
            .setAction(() => {
                leads.delete(leads.model.id).then(() => {
                    leads.state.splice(leads.index, 1);
                    leads.index = null;
                    router.push({ name: 'Leads', params: route.params });
                });

                PopUp.close();
            })
            .open(require('@/Ship/components/PopUps/Confirm'));
        }

        function closeLead() {
            let isLock = false;
            Object.keys(STATE.UNSAVED_DATA).forEach(key => {
                if (STATE.UNSAVED_DATA[key].length > 0) {
                    isLock = true;
                }
            });
            isLock
                ? PopUp.open(require('@/Ship/components/PopUps/UnSavedAlert')).setCaption(i18n.global.t('message.saving_data', 'Saving data'))
                : router.push({ name: 'Leads', params: route.params });
        }

        const openInNewTab = () => {
            window.open(`${route.params.locale ? `/${route.params.locale}` : ''}/leads/${leads.model.id}`,'_blank');
        }

        return {
            tab,
            leads,
            PopUp,
            profile,
            impersonate,
            settings,
            openTab,
            leadArchiving,
            removeLead,
            closeLead,
            openInNewTab
        };
    }
};
</script>

<style lang="scss" scoped>
.viewbar {
    z-index: 4;
    overflow: visible;
    box-shadow: -10px 12px 16px -6px #000000bb;
}

.lead-bar {
    padding: 0 15px;
    height: var(--bar-height, $bar-height);
    //background-color: var(--env-bg, $env-bg);

    .lead-id {
        color: var(--title-color, $title-color);
    }

    .balance {
        display: inline-flex;
        align-items: center;
        margin-left: 10px;
        padding-left: 10px;
        color: var(--main-text-color, $main-text-color);
        border-left: 1px solid var(--separator-color, $separator-color);
    }

    .g-button {
        width: 35px;
        height: 35px;
        color: white;
        margin: 0 2px;
        font-size: 0;
        background-color: transparent;
        fill: var(--main-text-color, $main-text-color);
        border: 1px solid var(--main-text-color, $main-text-color);
        position: relative;

        &.online-indicator {
            border: none;

            &::after {
                left: 50%;
                width: 100px;
            }

            &.active {
                fill: var(--danger-hover, $danger-hover);
                //border: 1px solid var(--danger-hover, $danger-hover);

                &:hover {
                    &::after {
                        background-color: var(--danger, $danger);
                    }

                    &::before {
                        border-bottom-color: var(--danger, $danger);
                    }
                }
            }

            &:not(.active) {
                fill: var(--disabled-color, $disabled-color);
                //border: 1px solid var(--disabled-color, $disabled-color);

                &:hover {
                    &::after {
                        background-color: var(--success, $success);
                    }

                    &::before {
                        border-bottom-color: var(--success, $success);
                    }
                }
            }
        }

        &.blocked-indicator {
            border: none;

            &.active {
                fill: var(--warning-hover, $warning-hover);
            }

            &:hover {
                &::after {
                    color: black;
                    background-color: var(--warning, $warning);
                }

                &::before {
                    border-bottom-color: var(--warning, $warning);
                }
            }
        }

        &.impersonate-btn:hover {
            fill: var(--btn-primary-color, $btn-primary-color);
            border-color: var(--primary, $primary);
            background-color: var(--primary, $primary);

            &::after {
                background-color: var(--primary, $primary);
            }

            &::before {
                border-bottom-color: var(--primary, $primary);
            }
        }

        &.activate-btn:hover {
            fill: var(--title-color, $title-color);
            border-color: var(--success, $success);
            background-color: var(--success, $success);

            &::after {
                background-color: var(--success, $success);
            }

            &::before {
                border-bottom-color: var(--success, $success);
            }
        }

        &.archive-btn:hover,
        &.remove-btn:hover {
            fill: var(--btn-primary-color, $btn-primary-color);
            border-color: var(--danger, $danger);
            background-color: var(--danger, $danger);

            &::after {
                background-color: var(--danger, $danger);
            }

            &::before {
                border-bottom-color: var(--danger, $danger);
            }
        }

        &::after {
            left: 50%;
            min-width: 68px;
            transform: translateX(-50%);
            top: calc(99% + var(--tooltip-arrow-size, $tooltip-arrow-size));
            z-index: 3;
        }

        &::before {
            left: 50%;
            transform: translateX(-50%);
            top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
            z-index: 3;
        }

        &:last-child::after {
            left: 15%;
        }
    }
}

.test {
    color: var(--danger, $danger);
}

aside {
    height: 100%;
    display: flex;
    align-items: stretch;
    position: relative;
}

.lead {
    flex-grow: 1;
    padding: 0 5px;
    overflow: auto;
    height: calc(100% - var(--bar-height, $bar-height));
    background-color: var(--env-bg, $env-bg);
    border: 1px solid var(--separator-color, $separator-color);
}

.tab-bar {
    position: relative;
    flex-shrink: 0;
    height: 100%;
    width: var(--bar-height, $bar-height);

    .tab-btn {
        position: relative;
        font-size: 1.2rem;
        border-radius: 5px;
        width: 100%;
        padding-top: 8px;
        height: var(--bar-height, $bar-height);
        color: var(--main-text-color, $main-text-color);
        fill: var(--main-text-color, $main-text-color);

        &.active {
            fill: var(--title-color, $title-color);
        }

        &.new-tab {
            position: absolute;
            top: 0;
            left: 0;
            border-bottom: 0 !important;

            &.with-tooltip {
                &::after {
                    min-width: 80px;
                }
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid var(--separator-color, $separator-color);
        }

        &:hover {
            fill: var(--title-color, $title-color);
        }

        &::after {
            top: 50%;
            transform: translateY(-50%);
            left: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
        }

        &::before {
            top: 50%;
            transform: translateY(-50%);
            left: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
            border-right-color: var(--tooltip-bg, $tooltip-bg);
        }
    }
}

.timeline-activity {
    width: 100%;
}
</style>