<script setup>

import { defineProps, defineEmits } from 'vue';
import { useShipActions } from '@/Ship';

const onlyNumberInput = useShipActions('onlyNumberInput');


const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    modelValue: {
        type: Number,
    },
    label: {
        type: String,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    min: {
        type: Number,
        default: 0,
    },
    max: {
        type: Number,
        default: 0,
    }
})

function checkValue (event) {
    if (event.target.value === '') {
        emit('update:modelValue', '');
        return;
    }
    if (Number(event.target.value) < props.min) {
        event.target.value = props.min;
    } else if (Number(event.target.value) > props.max) {
        event.target.value = props.max;
    }

    emit('update:modelValue', event.target.value);
}

</script>

<template>
    <fieldset class="g-range" v-bind:class="{ disabled: disabled }">
        <legend>{{ label }}</legend>

        <g-flex align="center" justify="between" gap="5">
            <input 
                v-bind:value="modelValue"
                v-on:input="emit('update:modelValue', $event.target.value)"
                v-bind="$attrs"
                v-bind:min="min"
                v-bind:max="max"
                type="range"
                class="range-input"
                v-bind:disabled="disabled"
            />
            <g-symbol v-if="disabled" name="lock" width="20" height="20"/>
            <g-input
                v-else
                type="number"
                v-bind="$attrs"
                v-bind:modelValue="modelValue"
                v-on:input="emit('update:modelValue', $event.target.value)"
                v-on:reset="emit('update:modelValue', '')"
                v-on:keypress="onlyNumberInput"
                v-on:keyup="checkValue"
            />
        </g-flex>
    </fieldset>
</template>

<style lang="scss" scoped>
.g-range {
    padding: 0 8px 6px 8px;
    border: 1px solid var(--input-border, $input-border);
    border-radius: var(--input-rounding, $input-rounding);

    & > legend {
        padding: 0 4px;
    }

    input.range-input {
        flex-grow: 1;
        height: 8px;
        width: 100%;
        border-radius: 5px;
        color: var(--title-color, $title-color);
        background-color: var(--field-active-bg, $field-active-bg);

        &::-webkit-slider-thumb {
            width: 20px;
            height: 20px;
            background-color: var(--primary, $primary);
            border-radius: 50%;
            cursor: pointer;
            -webkit-appearance: none;
        }

        &:focus::-webkit-slider-thumb {
            box-shadow: var(--bar-shadow, $bar-shadow);
        }

        &::placeholder {
            color: transparent;
        }

        &:focus {
            outline: none;
        }
    }

    .g-input {
        margin-top: -5px;
        width: 50px;
        flex-shrink: 0;
        height: calc(var(--field-height, $field-height) - 12px) !important;

        &:deep(.g-symbol) {
            margin-right: 4px;
        }

        &:deep(input) {
            text-align: center;
            font-weight: bold;
            font-size: 11px !important;
            padding: 0 !important;
        }
    }

    .g-symbol {
        margin-bottom: 2px;
        fill: var(--main-text-color, $main-text-color);
    }
}

</style>
