<script setup>

import { onMounted, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { STATE } from '@/Ship';
import { useEnvComponents } from '@overlay';
import { useProfileActions } from '@profile';

import SaveIndicator from '@overlay/environment/SaveIndicator.vue';
import GSymbol from "@/Ship/components/global/symbol/GSymbol.vue";
import Notifications from '@/Containers/Notifications/Notifications';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const {
    BrandMenu,
    AccountMenu
} = useEnvComponents();

const isLargeScreen = ref(window.innerWidth > 1285);

const { locale } = useI18n();
const language = ref(locale);

onMounted(() => {
	window.addEventListener('resize', function () {
		isLargeScreen.value = window.innerWidth > 1285;
		STATE.IS_SHOW_SIDEBAR = isLargeScreen.value;
	});
	STATE.IS_SHOW_SIDEBAR = profile.state?.env?.isShowSidebar || STATE.IS_SHOW_SIDEBAR;
});

function saveState(key, value) {
	if (!profile.state.env) {
		profile.state.env = {};
	}
	profile.state.env[key] = value;
	profile.saveState();
}

const currentTime = ref();
setInterval(() => {
    const now = new Date();
    currentTime.value = [
        now.getUTCHours().toString().padStart(2, '0'),
        now.getUTCMinutes().toString().padStart(2, '0'),
        now.getUTCSeconds().toString().padStart(2, '0')
    ].join(':')
}, 1000);

</script>

<template>
    <div class="topbar">
        <router-link class="logo" v-bind:to="{ name: 'Welcome', params: $route.params }">
            <g-symbol name="time" width="22" height="22"/>
            GMT <span class="time">{{ currentTime }}</span>
        </router-link>
        <div style="flex-grow: 1;">
            <g-hamburger v-model:modelValue="STATE.IS_SHOW_SIDEBAR" v-on:change="saveState('isShowSidebar', STATE.IS_SHOW_SIDEBAR)"/>
        </div>
        <div class="toolbar">
            <slot/>
            <router-link v-if="profile.can('Console Use')" class="console-link" v-bind:to="{ name: 'StyleKit' }">Console</router-link>
            <brand-menu/>
            <notifications/>
            <account-menu/>
            <save-indicator/>
        </div>

        <g-lang-select v-model:language="language" v-on:selectedLang="saveState('language', $event)"/>
    </div>

</template>

<style lang="scss" scoped>
.console-link {
    color: var(--separator-color, $separator-color);
}

.topbar {
    z-index: 5;
    grid-area: tbr;
    width: 100%;
    padding: 0 10px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--bar-height, $bar-height);
    background-color: var(--bar-bg, $bar-bg);
    box-shadow: 0 10px 10px -12px #00000055;

    & > .toolbar {
        gap: 10px;
        display: flex;
        align-items: center;
    }

    .logo {
        font-size: 20px;
        font-weight: bold;
        fill: var(--secondary-hover, $secondary-hover);
        color: var(--secondary-hover, $secondary-hover);
        //color: var(--title-text-color, $title-text-color);
        gap: 8px;
        display: flex;
        align-items: center;

        & > .time {
            color: var(--title-text-color, $title-text-color);
        }
    }

    .g-hamburger {
        z-index: 10;
    }
}
</style>