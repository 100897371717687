//import { useBrandActions } from '@brand';
import { ApiClient, ResourcesManager, STATE } from "@/Ship";

//const getBrandResource = useBrandActions('getBrandResource');

export default id => {

    STATE.BRAND = id;

    //const brand = getBrandResource();
    //brand.index = brand.state.findIndex(brand => brand.id === id);

    if (!!ApiClient.defaults.params) {
        ApiClient.defaults.params.brand_id = id;
    } else {
        ApiClient.defaults.params = {
            brand_id: id
        };
    }

    ResourcesManager.reloadAll(['Brand', 'Profile']);
};
