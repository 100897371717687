import { useBrandResources } from '@brand';
import { ResourcesManager } from "@/Ship";
import { useProfileActions } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');

export default (reload = false) => {

    const profile = getProfileResource();
    if (profile.cant('Brand View')) {
        return {};
    }

    const brand = useBrandResources('Brand');
    if (reload || (!brand.isLoaded && !brand.isLoading)) {
        brand.sort('id', 'desc');
        brand.load();
    }

    ResourcesManager.addResource(brand, 'Brand');

    return brand;
};
