<script setup>
import { computed, ref, reactive } from 'vue';
import { useLeadActions, useLeadComponents } from '@lead';
import { useCountryActions } from '@country';
import { useProfileActions } from '@profile';
import { i18n, STATE} from '@/Ship';
import { useAuthComponents } from '@auth';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getCountryResource = useCountryActions('getCountryResource');
const countries = getCountryResource();

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const countryName = ref('');
const countryIndex = ref(null);


const PasswordInput = useAuthComponents('PasswordInput');

const {
    Condition,
    Fees,
    Marketing,
    LeadDetails,
} = useLeadComponents();

if (!lead.model.specifics) {
    lead.model.specifics = {
        city: null,
        region: null,
        address: null,
        birthday: null,
        age: null,
        postcode: null,
        job_position: null,
        crypto_fee: null,
        stock_fee: null,
        forex_fee: null,
        multipliers: {},
        documents: {},
    };
}

if (!lead.model.specifics?.multipliers || Array.isArray(lead.model.specifics.multipliers)) {
    lead.model.specifics.multipliers = {};
}

const payload = reactive({});
const state = reactive({});

const errors = reactive({});

setState();

function addFieldToChangeList(field) {
    if (!STATE.UNSAVED_DATA.profile) {
        STATE.UNSAVED_DATA.profile = [];
    }
    if (!STATE.UNSAVED_DATA.profile.includes(field)) {
        STATE.UNSAVED_DATA.profile.push(field);
    }
}

const model = new Proxy(payload, {
    get: (target, key) => {
        return target[key] || state[key];
    },
    set: (target, key, value) => {
        if (value === state[key]) {
            delete payload[key];
            STATE.UNSAVED_DATA.profile.splice(STATE.UNSAVED_DATA.profile.indexOf(key), 1);
        } else {
            target[key] = value;
            addFieldToChangeList(i18n.global.t(`lead.${key}`, key));
        }

        return true;
    }
});

function save() {
    lead.save(lead.model.id, payload).then(({ refusal }) => {

        Object.keys(refusal).forEach(key => {
            delete payload[key];
            errors[key] = refusal[key];
        });

        Object.assign(state, payload);
        Object.assign(lead.state[lead.index], state);
        reset();
    });
}

function reset() {
    Object.keys(payload).forEach(key => delete payload[key]);
    STATE.UNSAVED_DATA.profile = [];
}

function setState() {
    [
        'first_name',
        'last_name',
        'email',
        'phone',
        'alt_phone',
        'telegram',
        'password',
        'country_code',
        'language',
        'phone_confirmed',
        'email_confirmed',
        'description'
    ].forEach(key => state[key] = lead.model[key]);
}

const email = computed({
    get: () => model.email,
    set: value => {
        model.email = value;
        return true;
    }
});

const phone = computed({
    get: () => model.phone,
    set: value => {
        model.phone = value;
        return true;
    }
});

const altPhone = computed({
    get: () => lead.model.alt_phone,
    set: value => {
        model.alt_phone = value;
        return true;
    }
});

const telegram = computed({
    get: () => model.telegram,
    set(value) {
        model.telegram = value;
        return true;
    }
});

const expands = ref(profile.state?.lead?.profile?.expand || [
    'profile',
    'condition'
]);

function expand(part) {
    if (expands.value.includes(part)) {
        expands.value.splice(expands.value.findIndex(item => item === part), 1);
    } else {
        expands.value.push(part);
    }

    profile.state.lead.profile = { expand: expands.value };
    profile.saveState();
}

function changePhoneConfirmation() {
    model.phone_confirmed = (model.phone_confirmed === 'No')
        ? 'Yes'
        : 'No';
}

function changeEmailConfirmation() {
    model.email_confirmed = (model.email_confirmed === 'No')
        ? 'Yes'
        : 'No';
}

const selectedCountry = computed({
    get() {
        const country = countries.state.find(country => country.code === model.country_code);
        if (country) {
            countryName.value = country.name;
            countryIndex.value = countries.state.indexOf(country);
            return countryName.value;
        }
    },
    set(name) {
        const country = countries.state.find(country => country.name === name);
        if (country) {
            countryName.value = name;
            model.country_code = country.code;
        }
    }
});
</script>

<template>
    <form v-on:submit.prevent>

        <fieldset class="profile" v-bind:class="{is_expand: expands.includes('profile')}">
            <legend v-on:click="expand('profile')">
                {{ $t('lead.profile') }}
                <g-symbol name="arrow-down" width="24" height="24"/>
            </legend>

            <div class="container expand">
                <g-field v-model="model.first_name"
                        v-bind:backlight="!!payload.first_name"
                        v-bind:label="$t('lead.first_name')"/>

                <g-field v-model="model.last_name"
                        v-bind:backlight="!!payload.last_name"
                        v-bind:label="$t('lead.last_name')"/>

                <g-field v-model="email"
                        type="email"
                        v-bind:backlight="!!payload.email"
                        v-bind:class-name="{ confirmed: lead.state[lead.index].email_confirmed === 'Yes' }"
                        v-bind:label="$t('lead.email')"
                        v-bind:readonly="profile.cant('Lead ContactsView')">
                    <g-symbol v-if="profile.cant('Lead ContactsView')" name="lock" width="18" height="18"/>
                </g-field>

                <g-field v-model="telegram"
                        v-bind:backlight="!!payload.telegram"
                        v-bind:label="$t('lead.telegram', 'Telegram')"
                        v-bind:readonly="profile.cant('Lead ContactsView')">
                    <g-symbol v-if="profile.cant('Lead ContactsView')" name="lock" width="18" height="18"/>
                </g-field>

                <g-field v-model="phone"
                         type="tel"
                         v-bind:backlight="!!payload.phone"
                         v-bind:class-name="{ confirmed: lead.state[lead.index].phone_confirmed === 'Yes' }"
                         v-bind:label="$t('lead.phone')"
                         v-bind:readonly="profile.cant('Lead ContactsView')">
                    <g-symbol v-if="profile.cant('Lead ContactsView')" name="lock" width="18" height="18"/>
                </g-field>

                <g-field v-model="altPhone"
                         type="tel"
                         v-bind:backlight="!!payload.alt_phone"
                         v-bind:label="$t('lead.additional_phone', 'Additional phone')"
                         v-bind:readonly="profile.cant('Lead ContactsView')">
                    <g-symbol v-if="profile.cant('Lead ContactsView')" name="lock" width="18" height="18"/>
                </g-field>

                <password-input v-model="model.password"
                                v-bind:backlight="!!payload.password"
                                v-bind:label="$t('user.password')"/>

                <div>
                    <g-checkbox v-on:change="changePhoneConfirmation"
                                v-bind:backlight="!!payload.phone_confirmed"
                                v-bind:checked="model.phone_confirmed === 'Yes' ? true : false"
                                v-bind:class="model.phone_confirmed">
                        {{ $t('lead.phone_confirmed', 'Phone Confirmed') }}
                    </g-checkbox>

                    <g-checkbox v-on:change="changeEmailConfirmation"
                                v-bind:backlight="!!payload.email_confirmed"
                                v-bind:checked="model.email_confirmed === 'Yes' ? true : false"
                                v-bind:class="model.email_confirmed">
                        {{ $t('lead.email_confirmed', 'Email Confirmed') }}
                    </g-checkbox>

                </div>

                <g-combobox v-model="selectedCountry"
                            v-bind:backlight="!!payload.country_code"
                            v-bind:label="$t('base.country')"
                            v-bind:options="countries.state.map(country => country.name)"
                            v-bind:disabled="profile.cant('Lead CountryCodeEdit')"
                            v-bind:error="errors.country_code"/>

                <g-combobox v-model="model.language"
                            v-bind:backlight="!!payload.language"
                            v-bind:options="['AR', 'DE', 'EN', 'ES', 'ET', 'IT', 'LT', 'LV', 'KA', 'KK', 'HE', 'PL', 'PT', 'RU']"
                            v-bind:label="$t('lead.language')"/>
                <div v-if="profile.can('Lead DescriptionView')" class="lead-description">
                    <g-textarea v-model="model.description"
                                rows="3"
                                v-bind:backlight="!!payload.description"
                                v-bind:label="$t('description', 'Description')"
                                v-bind:readonly="profile.cant('Lead DescriptionEdit')"
                                v-bind:error="errors.description"/>
                </div>

                <g-flex justify="end" class="btn-bar full-row" gap="8">
                    <g-button class="reset-btn" type="button" v-on:click="reset" v-bind:disabled="!Object.keys(payload).length">
                        <g-symbol name="refresh" width="18" height="18"/>
                        {{ $t('base.reset', 'Reset') }}
                    </g-button>
                    <g-button class="save-btn" type="button" v-on:click="save" v-bind:disabled="!Object.keys(payload).length">
                        <g-symbol name="save" width="18" height="18"/>
                        {{ $t('base.save', 'Save') }}
                    </g-button>
                </g-flex>
            </div>
        </fieldset>

        <fieldset class="condition" v-bind:class="{is_expand: expands.includes('condition')}">
            <legend v-on:click="expand('condition')">
                {{ $t('lead.condition', 'Condition') }}
                <g-symbol name="arrow-down" width="24" height="24"/>
            </legend>
            <div class="container expand">
                <condition/>
            </div>
        </fieldset>

        <fieldset v-if="profile.can('Lead FeeView')" class="fees" v-bind:class="{is_expand: expands.includes('fees')}">
            <legend v-on:click="expand('fees')">
                {{ $t('lead.fees', 'Fees') }}
                <g-symbol name="arrow-down" width="24" height="24"/>
            </legend>

            <div class="expand">
                <fees/>
            </div>
        </fieldset>

        <fieldset class="marketing" v-bind:class="{is_expand: expands.includes('marketing')}">
            <legend v-on:click="expand('marketing')">
                {{ $t('lead.marketing', 'Marketing') }}
                <g-symbol name="arrow-down" width="24" height="24"/>
            </legend>

            <div class="expand">
                <marketing v-bind:campaigns="lead.model.campaigns"/>
            </div>
        </fieldset>

        <fieldset v-if="profile.can('Lead DetailsView')" class="details"
                  v-bind:class="{is_expand: expands.includes('details')}">
            <legend v-on:click="expand('details')">
                {{ $t('lead.details', 'Details') }}
                <g-symbol name="arrow-down" width="24" height="24"/>
            </legend>
            <div class="container expand">
                <lead-details v-bind:specifics="specifics"/>
            </div>
        </fieldset>
    </form>
</template>

<style scoped lang="scss">
fieldset {
    position: relative;
    padding: 20px 10px;
    border-top: 1px solid var(--separator-color, $separator-color);

    & > legend {
        padding: 0 4px;
        font-size: 1.2rem;
        cursor: pointer;
        color: var(--warning-hover, $warning-hover);

        & > .g-symbol {
            right: 6px;
            padding: 2px;
            position: absolute;
            border-radius: 50%;
            border: 1px solid var(--separator-color, $separator-color);
            fill: var(--main-text-color, $main-text-color);
            background-color: var(--bar-bg, $bar-bg);
            transform: rotate(180deg);
            transition: transform 0.3s;
        }
    }

    & > .expand {
        max-height: 600px;
        transition: max-height 0.3s;
    }

    & > .container {
        align-items: start;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr;

        & > .full-row {
            grid-column: 1/3;
        }
    }

    &:not(.is_expand) {
        & > legend > .g-symbol {
            transform: rotate(0deg);
            fill: var(--warning-hover, $warning-hover);
        }

        & > .expand {
            max-height: 0;
            overflow: hidden;
        }
    }
}

.btn-bar {
    padding-top: 20px;
}

.btn-bar > .g-button {
    padding: 0 30px;
    min-width: 130px;
    font-size: 15px !important;

    &.reset-btn {
        fill: var(--main-text-color, $main-text-color);
        color: var(--main-text-color, $main-text-color);
        background-color: transparent;
        border: 1px solid var(--separator-color, $separator-color);

        &:hover {
            color: white;
            fill: white;
            background-color: var(--separator-color, $separator-color);
        }
    }

    &.save-btn {
        background-color: var(--primary, $primary);

        &:hover {
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    & > .g-symbol {
        margin-right: 4px;
    }
}

.marketing {
    height: 50%;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color) transparent;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 1ex;
        background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
    }
}

.g-field {
    &.confirmed:deep(fieldset) {
        border-color: var(--success, $success);

        & > legend {
            color: var(--success-hover, $success-hover);
        }
    }
}

.lead-description {
    grid-column: 1/3;
}

.g-checkbox {
    display: block;
    margin: 4px 0;
}

</style>