<script>
import { ref } from 'vue';
import { i18n, PopUpManager as PopUp } from '@/Ship';
import { useFinanceComponents } from '@finance';
import { useProfileActions } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');

const {
    LeadWallets,
    LeadDeposits,
    LeadWithdraws,
} = useFinanceComponents();
export default {
    components: {
        LeadWallets,
        LeadDeposits,
        LeadWithdraws,
    },

    setup() {
        const profile = getProfileResource();
        const tab = ref(profile.state.lead.subtab || 'LeadDeposits');

        function openTab(tabName) {
            tab.value = tabName;
            profile.state.lead.subtab = tabName;
            profile.saveState();
        }

        function makeDepositPopUp() {
            PopUp.open(require('@finance/components/PopUps/MakeDeposit'))
                .setCaption(i18n.global.t('finances.make_deposit', 'Make Deposit'));
        }

        return {
            tab,
            profile,
            makeDepositPopUp,
            openTab,
        }
    }
}
</script>

<template>
    <div class="tab-bar">
        <span v-if="profile.can('Wallet View')"
              class="tab-btn"
              v-bind:class="{active: tab === 'LeadWallets'}"
              v-on:click="openTab('LeadWallets')">
            {{ $t('finances.wallets', 'Wallets') }}
        </span>
        <span v-if="profile.can('Deposit View')"
              class="tab-btn"
              v-bind:class="{active: tab === 'LeadDeposits'}"
              v-on:click="openTab('LeadDeposits')">
            {{ $t('finances.deposits', 'Deposit') }}
        </span>
        <span v-if="profile.can('Withdraw View')"
              class="tab-btn"
              v-bind:class="{active: tab === 'LeadWithdraws'}"
              v-on:click="openTab('LeadWithdraws')">
            {{ $t('finances.withdraws', 'Withdraws') }}
        </span>

        <div class="toolbar">
            <g-button v-if="profile.can('Deposit Make')"
                      class="with-tooltip"
                      type="button"
                      v-on:click="makeDepositPopUp"
                      v-bind:data-tooltip="$t('finances.make_deposit', 'Make Deposit')">
                <g-symbol name="plus" width="15" height="15"/>
            </g-button>
        </div>
    </div>
    <component v-bind:is="tab"/>
</template>

<style lang="scss" scoped>
.tab-bar {
    padding: 10px;
    z-index: 2;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: sticky;
    top: 0;
    background-color: inherit;
    border-bottom: 1px solid var(--separator-color, $separator-color);

    & > .tab-btn {
        font-size: 1.2rem;

        &.active {
            font-weight: bold;
            color: var(--title-color, $title-color);
        }
    }
}

.toolbar {
    flex-grow: 1;
    text-align: right;

    & > .g-button {
        width: 32px;
        height: 32px;
        color: white;
        margin: 0 2px;
        font-size: 0;
        background-color: transparent;
        fill: var(--main-text-color, $main-text-color);
        border: 1px solid var(--main-text-color, $main-text-color);
        position: relative;

        &:hover {
            border-color: var(--success, $success);
            background-color: var(--success, $success);
            fill: var(--btn-primary-color, $btn-primary-color);
        }

        &::after {
            top: 50%;
            line-height: 1;
            padding: 8px;
            transform: translateY(-50%);
            right: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
        }

        &::before {
            top: 50%;
            transform: translateY(-50%);
            right: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
            border-left-color: var(--tooltip-bg, $tooltip-bg);
        }
    }
}
</style>