<script setup>
import { ref } from 'vue';
import { useLeadActions } from '@lead';
import { useProfileActions } from '@profile';
import { useCommentActions, useCommentComponents } from '@comment';

const Comment = useCommentComponents('Comment');

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getCommentResource = useCommentActions('getCommentResource');
const comments = getCommentResource(
    {
        includes: ['user'],
        filters: { lead_id: [lead.model.id] },
    },
    true,
    'LeadComments Component'
);

const newComment = ref();

function addComment(event) {
    if (event.shiftKey) {
        return false;
    }

    comments.create({
        lead_id: lead.model.id,
        user_id: { name: profile.model.id },
        body: newComment.value.trim(),
    }).then(comment => {
        newComment.value = '';
    });
}

function deleteComment(id) {
    comments.delete(id).then(() => {
        const index = comments.state.findIndex(comment => comment.id === id);

        if (index > -1) {
            comments.state[index].deleted_at = new Date().toLocaleString();
        }
    });
}

</script>

<template>
    <g-preloader v-if="comments.isLoading"/>
    <g-caption class="info" weight="500" size="4" v-if="!comments.state.length && !comments.isLoading"><div>{{$t('base.no_comments')}}</div></g-caption>
    <div class="comments">
        <comment v-for="comment in comments.state"
                 v-bind:key="`comment-${comment.id}`"
                 v-bind:comment="comment"
                 v-on:delete="deleteComment(comment.id)"
                 v-on:restore="comments.restore(comment.id)">
            <div class="text">{{ comment.body }}</div>
        </comment>
    </div>
    <form class="comment-form" v-if="profile.can('Comment Make')" v-on:submit.prevent="addComment">
        <g-textarea v-model="newComment" rows="2"
                    maxlength="1000"
                    v-on:keyup.shift.enter.stop
                    v-on:keyup.enter.prevent="addComment"/>
        <g-button class="save-btn">
            <g-symbol name="send" width="20" height="20"/>
        </g-button>
    </form>
</template>

<style scoped lang="scss">
.comment-form {
    gap: 2px;
    display: flex;
    bottom: 0;
    position: sticky;
}

.g-textarea {
    flex-grow: 1;
}

.g-button {
    padding: 0;
    width: 40px;
    font-size: 0 !important;
    height: auto !important;
}

.text {
    white-space: pre-wrap;
}

.info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 18px;
    margin-top: 50%;

    & > div {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
}
</style>