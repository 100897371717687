<script setup>

import { computed, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useProfileActions } from '@profile';
import { useSettingsActions } from '@settings';

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource('account-levels');

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const payload = reactive({});

const { fallbackLocale } = useI18n();

let timeout = null;

function save() {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
        settings.save('general', payload).then(() => {
            Object.keys(payload).forEach(key => delete payload[key]);
        });
    }, 2000);
}

const languages = computed(() => fallbackLocale.value.map(code => {
    return { v: code }
}));

const useWhitelist = computed({
    get: () => settings.general?.useWhitelist || false,
    set: (value) => {
        settings.general.useWhitelist = value;
        payload.useWhitelist = value;

        save();

        return true;
    }
});

const defaultLanguage = computed({
    get: () => settings.general?.defaultLanguage || '',
    set: value => {
        settings.general.defaultLanguage = value;
        payload.defaultLanguage = value;

        save();

        return true;
    }
});
</script>

<template>
    <fieldset class="crm general" v-bind:class="{ disabled: profile.cant('Settings GeneralEdit') }">
        <legend>CRM Settings</legend>
        <g-flex gap="10" justify="between">
            <g-flex class="whitelist-switcher" align="center" justify="start" gap="8">
                <label>Enable whitelist</label>
                <g-switch v-model:checked="useWhitelist"/>
            </g-flex>

            <g-flex gap="8">
                <g-select v-model="defaultLanguage"
                          label="Default Language"
                          v-bind:options="languages"
                          option-text="v"
                          option-value="v"/>
            </g-flex>
        </g-flex>
    </fieldset>
</template>

<style scoped lang="scss">
.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.g-select {
    width: 150px;
}

.whitelist-switcher {
    white-space: nowrap;
}
</style>