<template>
    <div class="g-fade-container" v-bind:class="[{disabled}, {active: isShow}]" ref="wrap">
        <label class="label" >
            <g-symbol class="icon"
                      v-bind:class="{'icon-light': STATE.IS_LIGHT_MODE}"
                      v-bind:name="icon"
                      width="40"
                      height="38"/>
            <input ref="inp"
                   v-bind:value="label"
                   v-on:focus="onFocus"
                   v-on:blur="onBlur"
                   v-on:click="onLabelClick"
                   readonly/>
            <span v-if="label" class="value">{{ label }}</span>
            <g-symbol v-if="showArrow" class="arrow" name="arrow-down" width="18" height="18"/>
        </label>
        <div class="container"
             ref="container"
             v-bind:class="[positionX, positionY]">
            <slot/>
        </div>
    </div>
</template>

<script>

import { ref, watch, onMounted } from 'vue';
import { STATE } from '@/Ship';

export default {
    name: 'GFadeContainer',
    computed: {
        STATE() {
            return STATE;
        }
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            require: true,
        },
        label: {
            type: String,
            default: '',
        },
        toggleOnClick: {
            type: Boolean,
            default: false,
        },
        showArrow: {
            type: Boolean,
            default: true,
        },
        show: {
            type: Boolean,
        }
    },
    emits: ['show', 'hide'],
    setup(props, ctx) {
        const inp = ref(null);
        const isShow = ref(false);
        const wrap = ref(null);
        const container = ref(null);
        const positionY = ref('top');
        const positionX = ref('right');

        const showList = () => {
            isShow.value = true;
            const rect = container.value.getBoundingClientRect();
            const rectWrap = wrap.value.getBoundingClientRect();
            positionY.value = (rectWrap.y + rect.height) > document.body.offsetHeight
                ? 'top'
                : 'bottom';
            positionX.value = (rectWrap.x + rect.width) > document.body.offsetWidth
                ? 'left'
                : 'right';

            ctx.emit('show');
        };
        const hideList = () => {
            setTimeout(() => {
                isShow.value = false;
                ctx.emit('hide');
            }, 100);
        };

        const onLabelClick = () => {
            if (!props.toggleOnClick) return;
            isShow.value ? hideList() : showList();
        };

        const onFocus = () => {
            if (!props.toggleOnClick) showList();
        };

        const onBlur = () => {
            if (!props.toggleOnClick) hideList();
        };

        onMounted(() => {
            inp.value.size = inp.value.value.length + 1;
        });

        watch(() => props.label, () => {
            inp.value.size = inp.value.value.length + 1;
        });

        watch(() => props.show, () => {
            if (props.show === undefined) return;
            props.show ? showList() : hideList();
        });

        return {
            inp,
            isShow,
            wrap,
            container,
            positionY,
            positionX,
            showList,
            hideList,
            onFocus,
            onBlur,
            onLabelClick,
        };
    }
};
</script>

<style lang="scss" scoped>
.g-fade-container {
    position: relative;
    height: var(--fade-container-height, $fade-container-height);
    border-radius: var(--fade-container-rounding, $fade-container-rounding);

    &.disabled {
        pointer-events: none;
    }

    & > label {
        height: 100%;
        font-size: 1rem;
        font-weight: bold;
        gap: 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: background-color 0.3s;
        color: var(--fade-container-btn-color, $fade-container-btn-color);

        & > input {
            width: 0;
            padding: 0;
            margin: 0;
            border-width: 0;

            &:focus {
                & + .value {
                    background-color: var(--fade-container-btn-bg, $fade-container-btn-bg);
                    color: var(--fade-container-btn-color-active, $fade-container-btn-color-active);
                }

                & ~ .arrow {
                    transform: rotate(-180deg);
                }
            }
        }

        & > .value {
            padding: 0 14px;
            cursor: pointer;
            color: inherit;
            border-radius: var(--fade-container-rounding, $fade-container-rounding);
            height: var(--fade-container-height, $fade-container-height);
            line-height: var(--fade-container-height, $fade-container-height);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 200px;
        }

        & > .icon {
            flex-shrink: 0;
            padding: 10px;
            background-color: var(--env-bg, $env-bg);
            border-radius: 50%;
            fill: var(--fade-container-btn-color, $fade-container-btn-color);
        }

        & > .arrow {
            flex-shrink: 0;
            fill: var(--main-text-color, $main-text-color);
            transition: transform 0.5s;
            margin-left: 5px;
        }
    }

    & > .container {
        z-index: 3;
        min-width: 100%;
        position: absolute;
        box-sizing: border-box;
        transform: translateX(-50%);
        box-shadow: 0 4px 18px -5px #000000aa;
        border-radius: 9px;
        overflow: hidden;
        max-height: 200px;
        transition: max-height 0.4s;

        &.bottom {
            top: 100%;
        }

        &.right {
            left: 0;
            transform: none;
        }

        &.left {
            right: 0;
            transform: none;
        }

        &.top {
            bottom: 100%;
        }
    }

    &:not(.active) {
        & > .container {
            max-height: 0;
            transition: max-height 0.4s;
        }
    }
}
</style>