import { useShipResources } from '@/Ship';
import {
  STACK,
  ApiClient,
} from '@/Ship';
import { reactive } from 'vue';
import { useProfileActions } from '@profile';

const { BaseResource } = useShipResources();
const getProfileResource = useProfileActions('getProfileResource');

export default class Notifications extends BaseResource {

    static #instance = null;

    endpoint = 'notifications';
    referer = 'Notifications Resource';
    links = reactive({});

    static getInstance() {
        if (!Notifications.#instance) {
          Notifications.#instance = new Notifications();
        }
        return Notifications.#instance;
    }

    load(params = {}, loadMore) {
        const profile = getProfileResource();
        this.loaded.value = false;
        this.loading.value = true;
        if (this.meta.showOnlyUnread) {
            params.is_unread_only = true;
        }
        if (typeof this.meta.typeId === 'number') {
            params.type_id = this.meta.typeId;
        }

        return STACK.push(() => ApiClient.get( `${this.endpoint}/users/${profile.id}`, {
            params,
            headers: {'Ref': this.referer}
        }))
            .then(response => {
                this.collection.value = loadMore ?
                    this.collection.value.concat(response.data || []) :
                    response.data || []
                this.loaded.value = true;
                this.loading.value = false;

                if (response.meta) {
                    Object.assign(this.meta, response.meta);
                }

                if (response.filters) {
                    Object.assign(this.filterset, response.filters);
                }

                if (response.links) {
                    Object.assign(this.links, response.links);
                }

                if (Array.isArray(this.onload)) {
                    this.onload.forEach(action => action(response));
                } else if (typeof this.onload === 'function') {
                    this.onload();
                }

                return response;
            })
            .finally(() => {
                this.loaded.value = true;
                this.loading.value = false;
            });
    }
}
