<script setup>
import { computed, onMounted, ref, reactive, onBeforeUnmount } from 'vue';
import { useCountryActions } from '@country';
import { i18n } from '@/Ship';
import { useSettingsActions } from '@settings';
import { useProfileActions } from '@profile';
import { preloaderAligner } from '@/Ship/components/global/preloaders/scrollHellper';

const getCountryResource = useCountryActions('getCountryResource');
const countries = getCountryResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();
const isLoading = ref(false);

const setCountries = useSettingsActions('setCountries');

const tab = ref('favorite');
const tabList = reactive({
    favorite: i18n.global.t(`countries.favorite_countries`),
    disabled: i18n.global.t(`countries.disabled_countries`),
});

const selectedIds = computed(() => countries.state
.filter(item => (tab.value === 'favorite')
    ? item.is_favorite
    : item.is_disabled
)
.map(item => item.code));

const payload = { favorite: [], unfavorite: [], enable: [], disable: [] };

onMounted(() => {
    preloaderAligner('.environment');
});

const setFavoriteCountries = (country) => {
    payload.favorite = payload.favorite
    .concat(selectedIds.value.filter(id => !payload.unfavorite.includes(id)))
    .filter((item, index, array) => array.indexOf(item) === index);

    const isChecked = !payload.favorite.includes(country.code);

    if (isChecked) {
        const index = payload.unfavorite.findIndex((p) => p === country.code);
        payload.unfavorite.splice(index, 1);
        payload.favorite.push(country.code);
    } else {
        const index = payload.favorite.findIndex((p) => p === country.code);
        payload.favorite.splice(index, 1);
        payload.unfavorite.push(country.code);
    }
};

const setDisableCountries = (country) => {
    payload.disable = payload.disable
    .concat(selectedIds.value.filter(id => !payload.enable.includes(id)))
    .filter((item, index, array) => array.indexOf(item) === index);

    const isChecked = !payload.disable.includes(country.code);

    if (isChecked) {
        const index = payload.enable.findIndex((p) => p === country.code);
        payload.enable.splice(index, 1);
        payload.disable.push(country.code);
    } else {
        const index = payload.disable.findIndex((p) => p === country.code);
        payload.disable.splice(index, 1);
        payload.enable.push(country.code);
    }
};

const onApply = () => {
    payload.favorite = payload.favorite.filter((country) => {
        const foundCountry = countries.state.find((c) => c.code === country);
        return foundCountry && foundCountry.is_favorite === 0;
    });

    payload.unfavorite = payload.unfavorite.filter((country) => {
        const foundCountry = countries.state.find((c) => c.code === country);
        return foundCountry && foundCountry.is_favorite === 1;
    });

    payload.enable = payload.enable.filter((country) => {
        const foundCountry = countries.state.find((c) => c.code === country);
        return foundCountry && foundCountry.is_disabled === 1;
    });

    payload.disable = payload.disable.filter((country) => {
        const foundCountry = countries.state.find((c) => c.code === country);
        return foundCountry && foundCountry.is_disabled === 0;
    });
    isLoading.value = true;
    setCountries(payload).then(() => {
        updateCountryStates();
        isLoading.value = false;
    });
};

function updateCountryStates() {

    for (let i = 0; i < countries.state.length; i++) {
        const country = countries.state[i];

        if (payload.favorite.includes(country.code)) {
            country.is_favorite = 1;
        }

        if (payload.unfavorite.includes(country.code)) {
            country.is_favorite = 0;
        }

        if (payload.enable.includes(country.code)) {
            country.is_disabled = 0;
        }

        if (payload.disable.includes(country.code)) {
            country.is_disabled = 1;
        }
    }
}
</script>

<template>
    <div class="countries-wrapper">

        <g-tabs v-bind:tab-list="tabList" v-model="tab"></g-tabs>

        <div class="countries">
            <div v-if="tab === 'favorite'" class="countries-tab">
                <g-preloader v-if="countries.isLoading || isLoading"/>
                <g-checkbox v-for="country in countries.state"
                            v-bind:key="`country-${country && country.code}`"
                            v-model="selectedIds"
                            v-bind:value="country && country.code"
                            v-on:change="setFavoriteCountries(country)"
                            v-bind:disabled="profile.cant('Settings CountriesEdit')">
                    {{ $t(`countries.${country.code}`) }}
                </g-checkbox>
            </div>
            <div v-else class="countries-tab">
                <g-preloader v-if="countries.isLoading || isLoading"/>
                <g-checkbox v-for="country in countries.state"
                            v-bind:key="`country-${country && country.code}`"
                            v-model="selectedIds"
                            v-bind:value="country && country.code"
                            v-on:change="setDisableCountries(country)"
                            v-bind:symbol="'close'"
                            class="danger"
                            v-bind:disabled="profile.cant('Settings CountriesEdit')">
                    {{ $t(`countries.${country.code}`) }}
                </g-checkbox>
            </div>

            <g-flex justify="end">
                <g-button v-on:click="onApply" class="save-btn">{{ $t(`base.save`) }}</g-button>
            </g-flex>
        </div>
    </div>
</template>

<style scoped lang="scss">
.countries-wrapper {
    padding: 10px;

    &:deep(.g-tabs) {
        padding: 10px;
    }
}

.countries {
    container-type: inline-size;
    container-name: countries;
    padding: 8px 15px 15px;
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);
    min-height: 160px;

    .g-checkbox {
        width: 100%;
        margin: 4px 0;
        display: inline-block;

        &:deep(.label) {
            display: inline-block;
            white-space: nowrap;
        }
    }
}

.countries-tab {
    column-gap: 10px;
}

.save-btn {
    padding: 0 30px;
    color: $save-btn-text-color;
    background-color: var(--save-btn-bg, $save-btn-bg);

    &:hover {
        background-color: $save-btn-hover;
    }
}

@container countries (min-width: 1500px) {
    .countries-tab {
        column-count: 7;
    }
}

@container countries (min-width: 1200px) and (max-width: 1500px) {
    .countries-tab {
        column-count: 6;
    }
}

@container countries (min-width: 800px) and (max-width: 1200px) {
    .countries-tab {
        column-count: 5;
    }
}

@container countries (min-width: 700px) and (max-width: 800px) {
    .countries-tab {
        column-count: 4;
    }
}

@container countries (min-width: 500px) and (max-width: 700px) {
    .countries-tab {
        column-count: 3;
    }
}

@container countries (min-width: 320px) and (max-width: 500px) {
    .countries-tab {
        column-count: 2;
    }
}
</style>