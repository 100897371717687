import { useTeamResources } from '@team';
import { ResourcesManager } from "@/Ship";
import { useProfileActions } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');

export default (reload = false) => {

    const profile = getProfileResource();
    if (profile.cant('Team View')) {
        return {};
    }

    const team = useTeamResources('Team');
    if (reload || (!team.isLoaded && !team.isLoading)) {
        team.setIncludes(['teamlead', 'users.roles']);
        team.sort('id', 'desc');
        team.load();
    }

    ResourcesManager.addResource(team, 'Team');

    return team;
};
