<script setup>

import { PopUpManager as PopUp } from '@/Ship';
import { defineAsyncComponent, shallowRef, ref } from 'vue';

const message = shallowRef(null);
let range = ref(false);

message.value = defineAsyncComponent(() => new Promise(resolve => {
    resolve(PopUp.message);
}));

function close() {
    if (range.value) {
        PopUp.close();
        range.value = false;
    }
}

</script>

<template>
    <div class="g-popup" v-bind:class="PopUp.popupClassName" v-on:click="close" v-on:mousedown="range = true">
        <div class="popup" v-on:click.stop v-on:mousedown.stop>
            <g-flex class="popup-header" justify="between" align="center">
                <g-caption size="4" weight="600" v-html="PopUp.caption"></g-caption>
                <g-symbol name="close" class="popup-close" v-on:click="PopUp.close()" height="24" width="24"/>
            </g-flex>
            <div class="popup-body">
                <div class="popup-message" v-html="PopUp.message"/>
                <slot/>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.g-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    overflow: auto;
    background-color: var(--popup-overlay, $popup-overlay);
}

.popup {
    top: 50%;
    left: 50%;
    position: absolute;
    //overflow: hidden;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 10px);
    background-color: var(--popup-bg, $popup-bg);
    border-radius: var(--popup-rounding, $popup-rounding);
    border: 1px solid var(--popup-border, $popup-border);
}

.popup-header {
    padding: 10px 20px;
    background-color: var(--popup-caption-bg, $popup-caption-bg);
    border-bottom: 1px solid var(--separator-color, $separator-color);
    border-top-left-radius: var(--popup-rounding, $popup-rounding);
    border-top-right-radius: var(--popup-rounding, $popup-rounding);

    .g-caption {
        margin-right: 20px;
    }
}

.popup-message {
    max-width: 340px;
    margin: 0 auto;
    font-size: 16px;
    text-align: center;

    &:deep(p) {
        margin: 8px 0;

        &:last-child {
            font-size: 18px;
        }
    }
}

.popup-body {
    padding: 40px;
    overflow: visible;
    max-height: calc(100% - 55px);
}

.popup-close {
    cursor: pointer;
    fill: var(--main-text-color, $main-text-color);

    &:hover {
        fill: var(--title-color, $title-color);
    }
}

.popup-notification {
    .popup-close {
        position: absolute;
        top: 33px;
        right: 32px;
        fill: var(--notifications-color, $notifications-color);
    }

    .popup-header {
        height: 0;
        padding: 0;
        border: 0;
    }

    .popup {
        min-width: 622px;
        border: 0;
    }

    .popup-body {
        padding: 33px 32px 0;
    }
}
</style>
