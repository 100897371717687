<script setup>

import { useSettingsComponents } from '@settings';
import { onMounted } from 'vue';
import { useProfileActions } from '@profile';
import { useRouter, useRoute } from 'vue-router';

const General = useSettingsComponents('General');

const router = useRouter();
const route = useRoute();
const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

onMounted(() => {
    if (profile.cant('Settings View')){
        router.push({name: "NoAccess", ...route.params, query: { redirectedFrom: route.name }})
    }
});
</script>

<template>
    <div class="settings environment">
        <div v-if="$route.name === 'Settings'">
            <general/>
        </div>
        <router-view v-else/>
    </div>
</template>

<style lang="scss" scoped>
.settings {
    position: relative;
    height: 100%;
}
</style>