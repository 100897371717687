<script setup>
import { computed, reactive } from 'vue';
import { useBrandActions } from '@brand';
import { useProfileActions } from '@profile';
import { verifyField } from '@/Ship';

const payload = reactive({});

const getBrandResource = useBrandActions('getBrandResource');
const brands = getBrandResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const errors = reactive({
    name: false,
    domain: false,
    redirect_url: false,
    theme: false,
});

const name = computed({
    get: () => payload.name || brands.model.name,
    set: value => {
        payload.name = value;
        errors.name = verifyField(value, ['require', 'minLength:2']);
        return true;
    }
});

const domain = computed({
    get: () => payload.domain || brands.model.domain,
    set: value => {
        payload.domain = value;
        errors.domain = verifyField(value, ['require', 'domain']);
        return true;
    }
});

const url = computed({
    get: () => payload.redirect_url || brands.model.redirect_url,
    set: value => {
        payload.redirect_url = value;
        errors.redirect_url = verifyField(value, ['require', 'url']);
        return true;
    }
});

const theme = computed({
    get: () => payload.theme || brands.model.theme,
    set: value => {
        payload.theme = value;
        errors.theme = verifyField(value, ['require', 'minLength:4']);
        return true;
    }
});

const isAllow = computed(() => {
    for (const key in errors) {
        if (errors[key] !== null) {
            return false;
        }
    }

    return true;
});

function selectBrand(index) {
    brands.index = index;
    for (const key in errors) {
        errors[key] = null;
    }
}

function resetBrand() {
    brands.index = null;
    for (const key in errors) {
        delete payload[key];
        errors[key] = false;
    }
}

async function removeBrand(id) {
    const index = brands.state.findIndex(item => item.id === id);
    if (index !== -1) {

        brands.delete(brands.state[index].id).then(() => {
            brands.state.splice(index, 1);
        });
    }
}

async function saveBrand() {
    if (Number.isInteger(brands.index)) {
        brands.save(brands.model.id, payload).then(() => {
            Object.assign(brands.state[brands.index], payload);
            resetBrand();
        });
    } else {
        //const result = brands.state.find(item => item.name === payload.name);

        brands.create({
            ...payload,
            section: 'brand'
        }).then(response => {
            resetBrand();
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.description) {
                const errorDescriptions = error.response.data.description;

                for (const key in errorDescriptions) {
                    if (errors[key] !== undefined) {
                        errors[key] = errorDescriptions[key][0];
                    }
                }
            }
        });
    }
}
</script>

<template>
    <fieldset class="brands general">
        <legend>{{ $t('settings.brands') }}</legend>

        <template v-if="profile.can('Brand View')">
        <div class="container custom-scrollbar">
            <table>
                <thead>
                <tr class="row head">
                    <td class="cell">id</td>
                    <td class="cell">Name</td>
                    <td class="cell">Domain</td>
                    <td class="cell">Redirect URL</td>
                    <td class="cell">Theme</td>
                    <td class="cell"></td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(brand, i) in brands.state"
                    class="row"
                    v-on:click="selectBrand(i)"
                    v-bind:key="`brands-${brand.id}`">
                    <td class="cell index">{{ brand.id }}</td>
                    <td class="cell brand">{{ brand.name }}</td>
                    <td class="cell target">{{ brand.domain }}</td>
                    <td class="cell target">{{ brand.redirect_url }}</td>
                    <td class="cell theme">{{ brand.theme }}</td>
                    <td class="cell actions" valign="middle">
                        <g-symbol v-if="profile.can('Brand Remove')"
                                  name="close"
                                  width="16"
                                  height="16"
                                  class="remove-btn"
                                  v-on:click.stop="removeBrand(brand.id)"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <form v-on:submit.prevent="saveBrand">
            <g-field v-model="name"
                    v-bind:label="$t('settings.brand_name', 'Brand name')"
                    v-bind:reset-btn="false"
                    v-bind:error="errors.name"/>
            <g-field v-model="domain"
                    v-bind:label="$t('settings.domain', 'Domain')"
                    v-bind:reset-btn="false"
                    v-bind:error="errors.domain"/>
            <g-field v-model="url"
                    label="Redirect URL"
                    v-bind:reset-btn="false"
                    v-bind:error="errors.redirect_url"/>
            <g-field v-model="theme"
                    v-bind:label="$t('settings.brand_theme', 'Brand theme')"
                    v-bind:reset-btn="false"
                    v-bind:error="errors.theme"/>

            <g-flex gap="8">
                <g-button v-bind:disabled="!Number.isInteger(brands.index)" class="cancel-btn" type="button" v-on:click="resetBrand">
                    {{ $t('base.reset', 'Reset') }}
                </g-button>

                <g-button type="submit"
                          v-bind:class="Number.isInteger(brands.index)  ? 'save-btn' : 'create-btn'"
                          v-bind:disabled="!isAllow || Number.isInteger(brands.index) ? profile.cant('Brand Edit') : profile.cant('Brand Make')">
                    {{
                        Number.isInteger(brands.index)
                            ? $t('base.save', 'Save')
                            : $t('base.create', 'Create')
                    }}
                </g-button>
            </g-flex>
        </form>
        </template>
    </fieldset>
</template>


<style scoped lang="scss">
.brands {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 15px 15px;
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);
    max-height: 566px;
    container-type: inline-size;

    & > .container {
        flex-grow: 1;
    }
}

table {
    width: 100%;
    margin-top: 10px;
}

.row {
    cursor: pointer;

    &:nth-child(odd) {
        background-color: var(--env-bg, $env-bg);
    }

    &:nth-child(even) {
        background-color: var(--bar-bg, $bar-bg);
    }

    &.head {
        background-color: var(--primary, $primary);
    }

    & > .cell {
        padding: 8px;

        &:last-child {
            text-align: right;
            font-size: 0;
        }
    }
}

.edit-btn,
.remove-btn {
    cursor: pointer;
    margin: 0 4px;
    fill: var(--main-text-color, $main-text-color);
}

.edit-btn:hover {
    fill: var(--warning-hover, $warning-hover);
}

.remove-btn:hover {
    fill: var(--danger-hover, $danger-hover);
}

form {
    margin-top: 8px;
    display: flex;
    align-items: end;
    justify-content: end;
    row-gap: 4px;
    column-gap: 8px;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    & > .g-field {
        flex-grow: 1;
    }
}

.g-button {
    width: 75px;
    margin-top: 10px;

    &.create-btn {
        background-color: var(--success, $success);
    }
}

@container (max-width: 680px) {
    form {
        flex-wrap: wrap;
    }
}
</style>