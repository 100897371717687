<script setup>
import { computed, reactive, ref } from 'vue';
import { useProfileActions } from '@profile';
import { useSettingsActions } from '@settings';
import _ from 'lodash';
import { i18n } from '@/Ship';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource('statuses');

const payload = reactive({
    name: '',
    target: ''
});

const error = ref('');

const targets = [
    { name: 'conversion' },
    { name: 'retention' },
    { name: 'KYC' },
    { name: 'deposit' },
    { name: 'withdraw' }
];

const targetFilters = [
    { name: 'All' },
    ...targets
];

const statuses = computed(() => {
    return payload.target
        ? settings.statuses.filter(status => status.target === payload.target.toLowerCase())
        : settings.statuses;
});

async function removeStatus(id) {
    const index = settings.statuses.findIndex(item => item.id === id);
    if (index !== -1) {

        settings.delete('status', { status_id: settings.statuses[index].id }).then(() => {
            settings.statuses.splice(index, 1);
        });
    }
}

async function addStatus() {
    if (payload.name && payload.target) {
        payload.name = _.capitalize(payload.name);
        const already = settings.statuses.find(item => item.name === payload.name && item.target === payload.target);

        if (already) {
            error.value = i18n.global.t('settings.status_name_already_exist');
        } else {
            settings.create({
               name: payload.name,
               target: payload.target,
               section: 'status'
            }).then(response => {
               settings.statuses.push(response);
               payload.name = '';
            }).catch(({response}) => {
               error.value = response.data.message;
            });
        }
    }
}
</script>

<template>
    <fieldset class="statuses">
        <legend>{{ $t('settings.statuses') }}</legend>

        <form v-on:submit.prevent="addStatus" v-bind:class="{ disabled: profile.cant('Settings StatusesEdit') }">
            <g-flex align="end" justify="flex-start" gap="5">
                <g-select v-model="payload.target"
                          v-on:change="error = null"
                          v-bind:label="$t('settings.target')"
                          v-bind:options="targets"
                          text-transform="capitalize"
                          option-text="name"
                          option-value="name"/>

                <g-field v-model="payload.name"
                         v-on:change="error = null"
                         v-bind:label="$t('settings.new_status')"
                         v-bind:disabled="!payload.target || profile.cant('Settings StatusesEdit')"
                         v-bind:error="error"
                         v-on:keyup.enter="addStatus"/>

                <g-button type="button"
                          v-on:click="addStatus"
                          class="add-btn"
                          v-bind:disabled="!payload.name || profile.cant('Settings StatusesEdit')">
                    <g-symbol name="send" width="20" height="20"/>
                </g-button>
            </g-flex>
        </form>

        <div class="container custom-scrollbar">
            <table>
                <tbody>
                <tr v-for="(status, i) in statuses" v-bind:key="`status-${status.id}`" class="row">
                    <td class="cell index">{{ i + 1 }}</td>
                    <td class="cell status">{{ status.name }}</td>
                    <td class="cell target">{{ status.target }}</td>
                    <td class="cell actions" valign="middle">
                        <g-symbol v-if="profile.can('Settings StatusesEdit')"
                                  name="close"
                                  width="16"
                                  height="16"
                                  class="remove-btn"
                                  v-on:click="removeStatus(status.id)"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </fieldset>
</template>


<style scoped lang="scss">
.statuses {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 15px 15px;
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);
    max-height: 566px;

    & > .container {
        flex-grow: 1;
    }
}

table {
    width: 100%;
    margin-top: 10px;
}

.row {
    &:nth-child(odd) {
        background-color: var(--env-bg, $env-bg);
    }

    &:nth-child(even) {
        background-color: var(--bar-bg, $bar-bg);
    }

    & > .cell {
        padding: 8px;

        &:last-child {
            text-align: right;
            font-size: 0;
        }
    }
}

.remove-btn {
    cursor: pointer;
    fill: var(--danger, $danger);

    &:hover {
        fill: var(--danger-hover, $danger-hover);
    }
}

form {
    margin-top: 8px;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .g-select {
        width: 150px;
    }

    .g-field {
        flex-grow: 1;
    }
}

.add-btn {
    color: white;
    padding: 0 20px;
    background-color: var(--primary, $primary);
    fill: var(--btn-primary-color, $btn-primary-color);

    &:hover {
        background-color: var(--primary-hover, $primary-hover);
    }
}
</style>